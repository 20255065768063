import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { startLoading, stopLoading } from "../Feature/Index";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { getCsrfToken } from "../../helpers";

export const getUser = createAsyncThunk(
    "user/getUserData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = "resource/users/me";
            if (getCsrfToken() === undefined) {
                dispatch(stopLoading());
                return {};
            }
            const res = await API.get(config.barong)(url);
            dispatch(stopLoading());
            return res;
        } catch (e) {
            dispatch(stopLoading());
            let errArr = [
                "authz.invalid_session",
                "authz.client_session_mismatch",
                "authz.csrf_token_mismatch",
            ];
            if (e.code === 401) {
                if (!errArr.includes(e.message[0])) {
                    // dispatch(errorToastSelector(e));
                }
            }
            return rejectWithValue(e);
        }
    }
);

export const updateUser = createAsyncThunk(
    "user/updateUser",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = "resource/profiles";
            const res = await API.put(config.barong)(url, payload);
            dispatch(successToastSelector({ message: ['resource.profile.updated'] }));
            dispatch(stopLoading());
            return res;
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);