import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { buildQueryString, buildQueryString2 } from "../../helpers";
import { fetchBalance } from "./Common";
import { startLoading, stopLoading, successResponse } from "../Feature/Index";

export const withdrawCallBack = createAsyncThunk(
    "wallet/withdrawCallBack",
    async (payload, { dispatch }) => {
        try {
            let selectedCurrency = payload.currency;
            let status = payload.status;
            const { t } = payload;
            delete payload.currency;
            delete payload.t;

            dispatch(startLoading());
            let url = `/account/fiats/withdraw/callback`;
            let res = await API.post(config.peatio)(url, payload);
            dispatch(successResponse(true));
            dispatch(fetchBalance(selectedCurrency))
            dispatch(fetchWithdraw({ currency: selectedCurrency }));
            if (status === 'success') {
                dispatch(
                    successToastSelector({ message: ['withdraw.request'] }, "success")
                );
            } else {
                dispatch(
                    successToastSelector({ message: ['withdraw.failed'] }, "error")
                );
            }
            dispatch(stopLoading());
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const fetchWithdraw = createAsyncThunk(
    "wallet/fetchWithdraw",
    async ({ params, filterData }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/withdraws?${params !== undefined ? buildQueryString2(params) : ''}&${filterData !== undefined
                ? buildQueryString2(filterData)
                : ''
                }`;
            const { data, headers } = await API.get2(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);

export const withdrawAmount = createAsyncThunk(
    "wallet/withdrawAmount",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/withdraws`;
            const { data } = await API.post(config.peatio)(url, payload);
            dispatch(successToastSelector({
                message: ['withdraw.request'],
                type: 'success',
            }));
            dispatch(stopLoading());
            return { data: data, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);

export const fetchDeposit = createAsyncThunk(
    "wallet/fetchDeposit",
    async ({ params, filterData }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/deposits?${params !== undefined ? buildQueryString2(params) : ''}&${filterData !== undefined
                ? buildQueryString2(filterData)
                : ''
                }`;
            const { data, headers } = await API.get2(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);

export const fetchBeneficiary = createAsyncThunk(
    "wallet/fetchBeneficiary",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/beneficiaries?${buildQueryString(payload)}`;
            const data = await API.get2(config.peatio)(url, payload);
            dispatch(stopLoading());
            return { list: data };
        } catch (e) {
            dispatch(stopLoading());
            if (e.message[0] !== 'account.withdraw.not_permitted') {
                dispatch(errorToastSelector(e));
            }

            return rejectWithValue(e);
        }
    }
);

export const deleteBeneficiary = createAsyncThunk(
    "wallet/deleteBeneficiary",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/beneficiaries/${payload.id}`;
            let datas = {
                otp: payload.otp,
            };
            const data = await API.delete(config.peatio)(url, payload, datas);
            dispatch(stopLoading());
            dispatch(successToastSelector({ message: ['account.beneficiary.beneficiary_delete'] }));
            return {
                id: payload.id,
                remBeneficiaryModal: true,
                status: true
            };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);

export const addBeneficiary = createAsyncThunk(
    "wallet/addBeneficiary",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/beneficiaries`;
            const data = await API.post(config.peatio)(url, payload);
            dispatch(successToastSelector({ message: ['account.beneficiary.beneficiary_add'] }));
            dispatch(stopLoading());
            dispatch(fetchBeneficiary({ currency: data?.currency }))
            return { list: data };
        } catch (e) {
            dispatch(stopLoading());
            if (e.message[0] !== 'account.withdraw.not_permitted') {
                dispatch(errorToastSelector(e));
            }

            return rejectWithValue(e);
        }
    }
);

export const fetchBeneficiaryOtp = createAsyncThunk(
    "wallet/fetchBeneficiaryOtp",
    async (payload, { rejectWithValue, dispatch }) => {
        const { id, type, t } = payload;
        try {
            dispatch(startLoading());
            let url = `/account/beneficiaries/${id}/${type}`;
            const data = await API.post(config.peatio)(url);
            dispatch(stopLoading());
            dispatch(successToastSelector({
                message: [
                    type === 'new' ?
                        t("withdraw.otp") :
                        t("withdraw.otp_resent")
                ]
            }));
            return { list: data, status: true };
        } catch (e) {
            dispatch(stopLoading());
            if (e.code === 422) {
                dispatch(successToastSelector({
                    message: ['account.beneficiary.please_wait'],
                    code: e.code,
                    type: 'error'
                }))

            }
            else {
                dispatch(errorToastSelector(e));
                return rejectWithValue(e);
            }
        }
    }
);

export const changeStateBeneficiary = createAsyncThunk(
    "wallet/changeStateBeneficiary",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/beneficiaries/${payload.id}/activate`;
            const data = await API.patch(config.peatio)(url, {
                pin: payload.pin,
            });
            dispatch(successToastSelector({ message: ['account.beneficiary.beneficiary_activated'] }));
            dispatch(stopLoading());
            dispatch(fetchBeneficiary({ currency: data?.currency }))
            return { changeData: data, success: true };
        } catch (e) {
            dispatch(stopLoading());
            if (e.message[0] == 'account.beneficiary.non_integer_pin') {
                dispatch(errorToastSelector(e));
                return rejectWithValue(e);
            }
            else {
                dispatch(errorToastSelector(e));
                return rejectWithValue(e);
            }

        }
    }
);