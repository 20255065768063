import React from "react";
import Lottie from "react-lottie-player";
import LoaderAnimation from "./Loader.json";

export const Loader = ({ loading }) => {
  return (
    <div className="loader-container">
      {loading && (
        <div className="loader">
          <Lottie
            className="lottieAntimationdata"
            animationData={LoaderAnimation}
            loop
            play
          />
        </div>
      )}
    </div>
  );
};
