import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { errorToastSelector } from "../Selector/ToastSelector";

export const getRangerFavMarketUpdate = createAsyncThunk(
    "ranger/getRangerFavMarketUpdate",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let url = `/account/fav_market/list`;
            const data = await API.get(config.peatio)(url, payload);
            return data

        } catch (e) {
            dispatch(errorToastSelector({ message: e.message, code: e.code, type: 'error' }));
            return rejectWithValue(e);
        }
    }
);

export const RangerFavMarketUpdate = createAsyncThunk(
    "ranger/RangerFavMarketUpdate",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let url = `/account/fav_market`;
            const data = await API.patch(config.peatio)(url, payload);
            return { data: data, success: true }

        } catch (e) {
            dispatch(errorToastSelector({ message: e.message, code: e.code, type: 'error' }));
            return rejectWithValue(e);
        }
    }
);


