import { createSlice } from "@reduxjs/toolkit";

export const FilterSlice = createSlice({
    name: "filter",
    initialState: {
        filterData: {},
        loading: false,
        error: false
    },

    reducers: {
        setFilter: (state, action) => {
            state.filterData = action.payload
        },
        resetFilter: (state, action) => {
            state.filterData = {}
        }
    },
    extraReducers: (builder) => {

    },
});
export const { setFilter, resetFilter } = FilterSlice.actions;
export default FilterSlice.reducer;
