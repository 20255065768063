import { createSlice } from "@reduxjs/toolkit";
import { changePassword, disableGoogleAuth, fetchFeeCurrencyCheck, forgotPassword, generateQrCode, getLastLogin, updateFeeCurrencyCheck, verifyQrCode } from "../../Services/Setting";

const settingSlice = createSlice({
    name: "setting",
    initialState: {
        submitButton: false,
        error: null,
        success: null,
        statusCode: null,
        qrCodeData: null,
        verifyAction: null,
        lastLogin: null,
        feeCurrencyValue: null,
        trading_level: null,
        loading: false,
        portfolio: null,
        successUpdatePortfolio: false,
        show: false

    },
    reducers: {
        showButtonLoad: (state) => {
            state.show = true
        },
        hideButtonLoad: (state) => {
            state.show = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.submitButton = true;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.submitButton = false;
            })
            .addCase(forgotPassword.rejected, (state) => {
                state.submitButton = false;
            })

            .addCase(changePassword.pending, (state) => {
                state.error = null;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.success = action.payload.success;
                state.statusCode = action.payload.statusCode;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.error = action.payload.error;
                state.statusCode = action.payload.statusCode;
                state.success = null
            })

            .addCase(getLastLogin.pending, (state) => {

            })
            .addCase(getLastLogin.fulfilled, (state, action) => {
                state.lastLogin = action.payload
            })
            .addCase(getLastLogin.rejected, (state, action) => {

            })

            .addCase(verifyQrCode.pending, (state) => {

            })
            .addCase(verifyQrCode.fulfilled, (state, action) => {
                state.success = action.payload.success;
                state.verifyAction = action.payload.verifyAction
            })
            .addCase(verifyQrCode.rejected, (state, action) => {
                state.success = null;
                state.statusCode = action.payload.statusCode;
                state.verifyAction = action.payload.verifyAction
            })

            .addCase(generateQrCode.pending, (state) => {
                state.error = false;
            })
            .addCase(generateQrCode.fulfilled, (state, action) => {
                state.loading = false;
                state.qrCodeData = action.payload.qrCodeData;
            })
            .addCase(generateQrCode.rejected, (state) => {
                state.error = true;
            })

            .addCase(disableGoogleAuth.pending, (state) => {

            })
            .addCase(disableGoogleAuth.fulfilled, (state, action) => {
                state.error = null;
                state.success = action.payload.success;
                state.verifyAction = action.payload.verifyAction
            })
            .addCase(disableGoogleAuth.rejected, (state, action) => {
                state.error = false;

            })

            .addCase(fetchFeeCurrencyCheck.pending, (state) => {
                state.feeCurrencyValue = null;
                state.trading_level = null
            })
            .addCase(fetchFeeCurrencyCheck.fulfilled, (state, action) => {
                state.feeCurrencyValue = action.payload.values;
                state.trading_level = action.payload.level
                state.portfolio = action.payload.values

            })
            .addCase(updateFeeCurrencyCheck.rejected, (state, action) => {
                state.feeCurrencyValue = null;
                state.trading_level = null
            })

            .addCase(updateFeeCurrencyCheck.pending, (state) => {
                state.successUpdatePortfolio = false;
            })
            .addCase(updateFeeCurrencyCheck.fulfilled, (state, action) => {
                state.successUpdatePortfolio = true;
                state.portfolio = action.payload.values
            })
            .addCase(fetchFeeCurrencyCheck.rejected, (state, action) => {
                state.successUpdatePortfolio = false;
            })



    },
});

export const { showButtonLoad, hideButtonLoad } = settingSlice.actions;

export default settingSlice.reducer;
