export const getMaskedEmail = (email) => {
  let skipChar = 3;
  let firstThreeChar = email.slice(0, skipChar);

  let domainIndexStart = email.lastIndexOf('@');
  let maskedEmail = email.slice(skipChar, domainIndexStart);
  maskedEmail = maskedEmail.replace(/./g, '*');
  let domain = email.slice(domainIndexStart, email.length);

  return firstThreeChar.concat(maskedEmail).concat(domain);
};

export const getMaskedNumber = (num) => {
  let addPlus = num.includes("+");
  let skipChar = 3;

  let firstFourChar = num.slice(0, skipChar);
  let maskedNumber = num.slice(skipChar, num.length - 3);
  let lastNumber = num.slice(num.length - 3);
  maskedNumber = maskedNumber.replace(/./g, '*');

  return `${addPlus ? '' : '+'}${firstFourChar
    .concat(maskedNumber)
    .concat(lastNumber)}`;
};
