import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { setCurrentMarket, setCurrentMarketIfUnset, setMarketTickers, setSuccess, startLoading, stopLoading } from "../Feature/Index";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { buildQueryString } from "../../helpers";
import { fetchBalance } from "./Common";

export const getMarkets = createAsyncThunk(
  "exchange/getMarkets",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = "public/markets";
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading(false));
      dispatch(setCurrentMarketIfUnset(res[0]))
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      dispatch(setCurrentMarket({}));
      return rejectWithValue(e);
    }
  }
);

export const marketGetTickers = createAsyncThunk(
  "markets/marketGetTickers",
  async (payload, { dispatch }) => {
    try {
      let url = `/public/markets/tickers`;
      const tickers = await API.get(config.peatio)(url);

      if (tickers) {
        const pairs = Object.keys(tickers);
        const convertedTickers = pairs.reduce((result, pair) => {
          result[pair] = tickers[pair].ticker;
          return result;
        }, {});
        dispatch(setMarketTickers(convertedTickers))
        return convertedTickers
      }

    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const submitOrders = createAsyncThunk(
  "exchange/submitOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      var side = payload.side;
      const { t } = payload
      delete payload.t;
      side = side.charAt(0).toUpperCase() + side.slice(1);

      dispatch(startLoading(true));
      let url = `market/orders`;
      let res = await API.post(config.peatio)(url, payload);

      dispatch(getOrderHistory({
        state: "wait",
        market: payload.market,
        order_by: "desc",
      }))

      dispatch(stopLoading(false));
      dispatch(fetchBalance())
      if (res) {
        dispatch(
          successToastSelector({ message: [side === "Buy" ? t("order_succ.buy") : t("order_succ.sell")] })
        );
      }
      return { list: res, status: true, side: side };
    } catch (e) {
      dispatch(stopLoading(false));
      let str = 'Validation failed: Origin volume must be greater';
      let preStr = 'Validation failed: Origin volume precision must be less than or equal'
      let message;
      if (e.message[0].includes(str)) {
        message = [`Amount must be greater than ${e.message[0].split(' ').at(-1)}`]
      } else if (e.message[0].includes(preStr)) {
        message = [`Decimal in amount field should be less than or equal to ${e.message[0].split(' ').at(-1)}`]
      } else {
        message = e.message
      }

      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "exchange/CancelOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/${payload.id}/cancel`;
      let res = await API.post(config.peatio)(url);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: ['orders.canceled'] })
        );
        dispatch(setSuccess(true));
        // dispatch(getOrderHistory({
        //   state: "wait",
        //   market: payload.marketId,
        //   order_by: "desc",
        // }))
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelAllOrder = createAsyncThunk(
  "exchange/cancelAllOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/cancel`;
      let res = await API.post(config.peatio)(url);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: ['orders.canceled'] })
        );
        dispatch(setSuccess(true));
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getOrderHistory = createAsyncThunk(
  "exchange/getOrderHistory",
  async ({ data, filterData }, { rejectWithValue, dispatch }) => {
    try {
      const { state } = data
      dispatch(startLoading(true));
      let url = `market/orders?${data !== undefined ? buildQueryString(data) : ''}&${filterData !== undefined
        ? buildQueryString(filterData)
        : ''
        }`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      const commonResponse = {
        list: res?.data,
        state: data?.state ? data?.state : "",
        status: true,
      };
      if (state === "wait") {
        return {
          ...commonResponse,
          totalOpenOrder: res?.headers?.total,
        }
      }
      else {
        return {
          ...commonResponse,
          total: res?.headers?.total,
        }
      }
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTradingRules = createAsyncThunk(
  "exchange/getTradingRules",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url =
        payload == undefined
          ? '/public/trading_fees'
          : [null, undefined].includes(payload?.uuid?.length)
            ? `/public/trading_fees?market_id=${payload.market_id}`
            : `/public/trading_fees?market_id=${payload.market_id}&uuid=${payload.uuid}`;

      let data = await API.get(config.peatio)(url);
      if (data.length == 0) {
        
        data = await API.get(config.peatio)('/public/trading_fees');
      }

      dispatch(stopLoading(false));
      return data[0];

    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
