import { createSlice } from "@reduxjs/toolkit";
import { fetchAddress } from "../../Services/AddressService";

const addressSlice = createSlice({
    name: "address",
    initialState: {
        list: [],
        loading: false,
        error: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAddress.pending, (state) => {
                state.loading = true
                state.error = false;
            })
            .addCase(fetchAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action?.payload?.list;
            })
            .addCase(fetchAddress.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
            })
    },
});

export const { } = addressSlice.actions;

export default addressSlice.reducer;
