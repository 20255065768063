import { createSlice } from "@reduxjs/toolkit";
import { getMarkets, getOrderHistory, getTradingRules, marketGetTickers, submitOrders } from "../../Services/ExchangeService";
import { getCloneData } from "../../../helpers/getCloneData";
import { klineArrayToObject } from "../../../helpers/ranger";
import { insertOrUpdate, insertOrUpdateHistory } from "../../../helpers/orders";

const initialState = {
  orderBookList: [],
  marketList: [],
  currentMarket: undefined,
  marketTickers: {},
  currentMarketId: "",
  baseCurrency: {},
  success: false,
  error: false,
  loading: false,
  favMarkets: [],
  actualFavMarkets: [],
  klinePeriod: "15m",
  askCurrentValue: undefined,
  bidCurrentValue: "",
  orderHistory: [],
  allOrders: [],
  allOrdersCount: 0,
  openOrdersCount: 0,
  tradingRules: [],
  tradingFee: { maker: '0.002' },
  selectedBid: {
    price: '',
    amount: '',
    total: ''
  },
  selectedAsk: {
    price: '',
    amount: '',
    total: ''
  },
  buyLoading: false,
  sellLoading: false,
  actualList: [],
  favList: [],
  list: []
};

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState: initialState,
  reducers: {
    searchMarket: (state, action) => {
      // Save the original lists if not already done
      if (state.actualList.length === 0 && state.list.length > 0) {
        state.actualList = state.list;
      } else if (state.list.length === 0 && state.actualList.length > 0) {
        state.list = state.actualList;
      }

      // Save the original favorite markets if not already done
      if (state.actualFavMarkets.length === 0 && state.favMarkets.length > 0) {
        state.actualFavMarkets = state.favMarkets;
      } else if (state.favMarkets.length === 0 && state.actualFavMarkets.length > 0) {
        state.favMarkets = state.actualFavMarkets;
      }

      // Initialize data and favData
      let data = [];
      let favData = [];

      // Check if the search query is not empty
      if (action.payload !== '') {
        // Market ticker search
        data = state.actualList.filter((obj) =>
          Object.keys(obj).some((key) =>
            (typeof obj['base_unit'] === 'string' && obj['base_unit'].includes(action.payload.toLowerCase())) ||
            (typeof obj['quote_unit'] === 'string' && obj['quote_unit'].includes(action.payload.toLowerCase()))
          )
        );

        // Market favorite search
        favData = state.actualFavMarkets.filter((obj) =>
          Object.keys(obj).some((key) =>
            (typeof obj['base_unit'] === 'string' && obj['base_unit'].includes(action.payload.toLowerCase())) ||
            (typeof obj['quote_unit'] === 'string' && obj['quote_unit'].includes(action.payload.toLowerCase()))
          )
        );
      } else {
        // If the search query is empty, use the original lists
        data = state.actualList;
        favData = state.actualFavMarkets;
      }

      // Update the market lists
      state.list = data;
      state.favMarkets = favData;
    },


    resetSubmitOrder: (state) => {
      state.success = false
      state.error = false
    },

    marketStreams: market => ({
      channels: [`${market.id}.trades`, `${market.id}.update`]
    }),

    setUnsetFavourite: (state, action) => {
      var setList = Object.assign([], state.marketList);
      var favMarketList = Object.assign([], state.favMarkets);
      setList.forEach((market, index) => {
        if (market.id === action.payload.id && action.payload.type === 'set') {
          setList[index]['isFav'] = 1;
          favMarketList.push(setList[index]);
        }
        if (
          market.id === action.payload.id &&
          action.payload.type === 'unset'
        ) {
          setList[index]['isFav'] = 0;
          var filterFavMarketList = favMarketList.filter(
            (favmarket) => favmarket.id !== action.payload.id
          );
          favMarketList = filterFavMarketList;
        }
      });
      state.marketList = setList;
      state.list = setList;
      state.loading = false;
      state.favMarkets = favMarketList
    },
    setCurrentMarket: (state, action) => {
      state.currentMarket = action.payload.currentMarket;
      state.currentMarketId = action.payload.currentMarketId;
    },
    setBaseCurrency: (state, action) => {
      state.baseCurrency = action.payload.baseCurrency;
    },
    setMarketTickers: (state, action) => {
      state.marketTickers = action.payload;

    },

    setCurrentMarketIfUnset: (action, state) => {
      state.currentMarket = action.payload
    },

    setOrderBook: (state, action) => {
      state.orderBookList = action.payload.orderBook;
    },
    setTrades: (state, action) => {
      let trades = getCloneData(state.tradeList);
      trades.splice(0, 1);
      trades.push(action?.payload?.trade);
      state.tradeList = trades;
    },
    resetCurrentMarketId: (state, action) => {
      state.currentMarketId = "";
    },
    setKlinePeriod: (state, action) => {
      state.klinePeriod = action.payload;
    },
    setKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = klineArrayToObject(action.payload.last);
      state.kline.marketId = action.payload.marketId;
      state.kline.period = action.payload.period;
    },
    resetKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = undefined;
      state.kline.marketId = undefined;
      state.kline.period = undefined;
    },
    setAskVal: (state, action) => {
      state.askCurrentValue = action?.payload;
    },
    setBidVal: (state, action) => {
      state.bidCurrentValue = action?.payload;
    },
    resetBidVal: (state, action) => {
      state.bidCurrentValue = "";
    },
    setSuccess: (state, action) => {
      state.success = action?.payload;
    },
    setExchangeState: (state, action) => {
      state[action?.payload?.key] = action?.payload?.data;
    },
    updateOpenOrder: (state, action) => {
      let openOrderList = getCloneData(state.orderHistory);
      let newData = insertOrUpdate(openOrderList, action.payload);
      state.orderHistory = newData;
    },
    updateAllOrder: (state, action) => {
      let allOrderList = getCloneData(state.allOrders);
      const arr = insertOrUpdateHistory(allOrderList, action.payload);
      state.allOrders = arr;
    },

    setSelectedBid: (state, action) => {
      let list = Object.assign({}, action.payload.selectedBid);
      state.selectedBid = list;
    },

    setSelectedAsk: (state, action) => {
      let list = Object.assign({}, action.payload.selectedAsk);
      state.selectedAsk = list;
    }

  },
  extraReducers: (builder) => {
    builder

      .addCase(getMarkets.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.loading = false;

        let favMarkets = [];
        let favMarketsId = JSON.parse(localStorage.getItem('favMarketsId'));
        action.payload.list?.forEach((market, index) => {
          if (
            favMarketsId != null &&
            favMarketsId.length > 0 &&
            favMarketsId.includes(market.id)
          ) {
            action.payload.list[index]['isFav'] = 1;
            favMarkets.push(action.payload[index]);
          } else {
            action.payload.list[index]['isFav'] = 0;
          }
        });
        state.marketList = action.payload?.list;
        state.list = action.payload.list
        state.loading = false;
        state.favMarkets = favMarkets

      })
      .addCase(getMarkets.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(marketGetTickers.pending, (state) => {
        state.error = false;
      })
      .addCase(marketGetTickers.fulfilled, (state, action) => {
        state.loading = false;
        state.marketTickers = action.payload;
      })
      .addCase(marketGetTickers.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getOrderHistory.pending, (state) => {
        state.error = false;
        state.orderHistory = [];
        state.allOrders = [];
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.callFrom === "setting") {
          state.allOrders = action?.payload?.list;
          state.allOrdersCount = action?.payload?.total;
        } else {
          if (action?.payload?.state === "wait") {
            state.orderHistory = action?.payload?.list;
            state.openOrdersCount = action?.payload?.totalOpenOrder;
          } else {
            state.allOrders = action?.payload?.list;
            state.allOrdersCount = action?.payload?.total;
          }
        }
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getTradingRules.pending, (state) => {
        state.error = false;
        state.tradingFee = {}
      })
      .addCase(getTradingRules.fulfilled, (state, action) => {
        console.log(action.payload, "asdlkajsdlkajs");
        state.loading = false;
        state.tradingFee = {} = action?.payload;
      })
      .addCase(getTradingRules.rejected, (state, action) => {
        state.error = true;
        state.tradingFee = {}
      })

      .addCase(submitOrders.pending, (state, action) => {
        state.success = false;
        state.error = false;
        // let checkType = action.payload.side
        // if (checkType === "buy") {
        //   state.buyLoading = false
        // }
        // else {
        //   state.sellLoading = false
        // }
      })
      .addCase(submitOrders.fulfilled, (state, action) => {
        state.success = true;
        // let checkType = action.payload.side;
        // if (checkType === "buy") {
        //   state.buyLoading = true
        // }
        // else {
        //   state.sellLoading = true
        // }
      })
      .addCase(submitOrders.rejected, (state, action) => {
        state.error = true;
        state.success = false
        // let checkType = action.payload.side;
        // if (checkType === "buy") {
        //   state.buyLoading = false
        // }
        // else {
        //   state.sellLoading = false
        // }
      });


  },
});

export const {
  searchMarket,
  resetSubmitOrder,
  setUnsetFavourite,
  setCurrentMarket,
  setBaseCurrency,
  setMarketTickers,
  setCurrentMarketIfUnset,
  setOrderBook,
  setTrades,
  resetCurrentMarketId,
  setKlineData,
  setKlinePeriod,
  setAskVal,
  setSelectedBid,
  setSelectedAsk,
  setBidVal,
  resetBidVal,
  resetKlineData,
  setSuccess,
  setExchangeState,
  updateOpenOrder,
  updateAllOrder,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
