import { createSlice } from "@reduxjs/toolkit";
import { fetchBalance, getCurrencies, updatePopUpStatus } from "../../Services/Common.js";

const commonSlice = createSlice({
    name: "common",
    initialState: {
        currencyList: [],
        balanceList: [],
        actualList: [],
        loading: false,
        error: false,
        currencyNetwork: null,
        exchangeData: {},
        depositSubTab: 'crypto',
        getCode: "",
        tab: "",
        status: false,
        language: "en",
        activeOrderTab: "open"

    },
    reducers: {
        getActiveLanguage: (state, action) => {
            state.language = action.payload
        },
        emptyGenerateCode: (state) => {
            state.getCode = ""
        },
        remove_currencies_list: (state) => {
            state.currencyList = []
        },
        tabName: (state, action) => {
            state.tab = action.payload
        },
        resetTabName: (state, action) => {
            state.tab = "";
        },
        searchBalnce: (state, action) => {
            if (state.actualList.length === 0 && state.balanceList.length > 0) {
                state.actualList = state.balanceList;
            } else if (state.balanceList.length === 0 && state.actualList.length > 0) {
                state.list = state.actualList;
            }
            if (action.payload !== '') {
                var data = state.actualList.filter(function (obj) {
                    return Object.keys(obj).some(function (key) {
                        return (
                            typeof obj['currency'] == 'string' &&
                            obj['currency'].includes(action.payload)
                        );
                    });
                });
            } else {
                data = state.actualList;
            }

            state.balanceList = data
        },
        setOrderTab: (state, action) => {
            state.activeOrderTab = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrencies.pending, (state) => {
                state.error = false;
            })
            .addCase(getCurrencies.fulfilled, (state, action) => {
                state.loading = true;
                state.currencyList = action.payload.list;
            })
            .addCase(getCurrencies.rejected, (state, action) => {
                state.error = true;
            })

            .addCase(fetchBalance.pending, (state) => {
                state.error = false;
            })
            .addCase(fetchBalance.fulfilled, (state, action) => {
                state.loading = false;
                state.balanceList = action.payload.list;
            })
            .addCase(fetchBalance.rejected, (state, action) => {
                state.error = true;
            })

            .addCase(updatePopUpStatus.pending, (state) => {
                state.error = false;
            })
            .addCase(updatePopUpStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload.status;
            })
            .addCase(updatePopUpStatus.rejected, (state, action) => {
                state.error = true;
                state.status = false
            })


    },
});

export const { emptyGenerateCode, setOrderTab, getActiveLanguage, remove_currencies_list, tabName, searchBalnce, resetTabName } = commonSlice.actions;

export default commonSlice.reducer;
