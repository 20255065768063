import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../Feature/Index";
import {
    errorToastSelector,
} from "../Selector/ToastSelector";
import { API } from "../../api";

export const klineFetch = createAsyncThunk(
    "kline/klineFetch",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading(true));
            const { market, resolution, from, to } = payload;
            const data = await API.get(config.peatio)(`/public/markets/${market}/k-line?period=${resolution}&time_from=${from}&time_to=${to}`)
            const convertedData = data.map(elem => {
                const [date, open, high, low, close, volume] = elem.map(e => {
                    switch (typeof e) {
                        case 'number':
                            return e;
                        case 'string':
                            return Number.parseFloat(e);
                        default:
                            throw new Error(`unexpected type ${typeof e}`);
                    }
                });

                return {
                    date: date * 1e3,
                    open,
                    high,
                    low,
                    close,
                    volume
                };
            });

            return convertedData

        } catch (e) {
            dispatch(stopLoading(false));
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);