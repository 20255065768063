export const defaultConfig = {
  title: 'Emperorcoin',
  baseUrl: 'https://stage-exchange.emperorcoin.com',
  api: {
    authUrl: 'https://stage-exchange.emperorcoin.com/api/v2/barong',
    tradeUrl: 'https://stage-exchange.emperorcoin.com/api/v2/peatio',
    applogicUrl: 'https://stage-exchange.emperorcoin.com/api/v2/applogic',
    rangerUrl: 'wss://stage-exchange.emperorcoin.com/api/v2/ranger',
    jumioUrl: 'https://lon.netverify.com/api',
  },
  minutesUntilAutoLogout: '35',
  withCredentials: true,
  captcha: {
    captchaType: 'turnstile',
    siteKey: "0x4AAAAAAAI7xrhjR4Z-kv5w",
  },
  refid: 'ID0C8BCB633C',
  defaultStorageLimit: 50,
  tablePageLimit: 50,
  defaultMarket: 'ethusdt',
  adminDepositAddress: 'changeme',
  gaTrackerKey: 'changeme',
  dt_currencies: 'xrp',
  gooney_api_key: 'KaFkeFjnkJbp2PMRQUHG',
  whitepaper_url: 'changeme',
};

export const Frontend = {
  config: defaultConfig,
};

window.env = window.env || defaultConfig;
Frontend.config = { ...window.env };

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha;

export const baseUrl = () => Frontend.config.baseUrl;
export const authUrl = () => Frontend.config.api.authUrl;
export const tradeUrl = () => Frontend.config.api.tradeUrl;
export const applogicUrl = () => Frontend.config.api.applogicUrl;
export const rangerUrl = () => Frontend.config.api.rangerUrl;
export const minutesUntilAutoLogout = () =>
  Frontend.config.minutesUntilAutoLogout || '5';
export const withCredentials = () => Frontend.config.withCredentials;
export const siteKey = () => Frontend.config.captcha.siteKey;
export const captchaType = () => Frontend.config.captcha.captchaType;
export const refid = () => Frontend.config.refid;
export const tablePageLimit = () => Frontend.config.tablePageLimit;
export const defaultStorageLimit = () => Frontend.config.defaultStorageLimit;
export const title = () => Frontend.config.title;
export const defaultMarket = () => Frontend.config.defaultMarket;
export const adminDepositAddress = () => Frontend.config.adminDepositAddress;
export const gaTrackerKey = () => Frontend.config.gaTrackerKey;
export const gooneyApiKey = () => Frontend.config.gooney_api_key;
export const whitepaperUrl = () => Frontend.config.whitepaper_url;

// export const dhanux_currencies = () => Frontend.config.dhanux_currencies;
