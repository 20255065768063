import { createSlice } from "@reduxjs/toolkit";
import { changeNumber, verifyAccount } from "../../Services/VerifyAccount";

export const verifyAccountSlice = createSlice({
    name: "verify",
    initialState: {
        error: false,
        status: null,
        phnStatus: null,
        checkStatus: false,
        loading: false,
        numErr: false
    },
    reducers: {
        checkEmailStatus: (state, action) => {
            state.status = action.payload.status
        },
        checkPhoneStatus: (state, action) => {
            state.phnStatus = action.payload.status
        },
        setPhoneStatusWhenChange: (state, action) => {
            state.checkStatus = action.payload.status
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyAccount.pending, (state) => {
                state.error = false;
                state.loading = true
            })
            .addCase(verifyAccount.fulfilled, (state, action) => {
                state.loading = false
                state.error = false
            })
            .addCase(verifyAccount.rejected, (state) => {
                state.error = true;
                state.loading = false
            })

            .addCase(changeNumber.pending, (state) => {
                state.numErr = false;
                state.loading = true
            })
            .addCase(changeNumber.fulfilled, (state, action) => {
                state.loading = false
                state.numErr = false
            })
            .addCase(changeNumber.rejected, (state) => {
                state.numErr = true;
                state.loading = false
            });


    },
});

export default verifyAccountSlice.reducer;
export const { checkEmailStatus, checkPhoneStatus, setPhoneStatusWhenChange } = verifyAccountSlice.actions;

