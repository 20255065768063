import React, { useEffect } from "react";
import { Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PublicRoute from "./routes/PublicRoute.jsx";
import { privateRoutesData, publicRoutesData } from "./routes/RoutingData.jsx";
import Landing from "./components/Landing/Landing";
import { rootName } from "./utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import { getUser } from "./redux/Services/UserServices";
import GoAlert from "./config/goAlert";
import { Loader } from "./components/Loader/Loader.jsx";
import {
  AmlPolicy,
  Contact,
  Exchange,
  Faq,
  Legal,
  Mainlayout,
  PageNotFound,
  Privacy,
  ResetPassword,
  Terms,
} from "./routes/LazyRoute.jsx";
import { useIdleTimer } from "react-idle-timer";
import { fetchLogout } from "./redux/Services/Logout.js";
import Ranger from "./config/ranger.jsx";
import i18next from "i18next";

const AppContent = ({ isAuthenticate, data, loading, navigate, dispatch }) => {
  const handleOnIdle = async () => {
    if (isAuthenticate) {
      let res = await dispatch(
        fetchLogout({
          uid: data?.uid,
        })
      );
      if (res.payload) {
        navigate("/login");
      }
      window.alert("Session expired, Please login again.");
    }
  };

  const { reset } = useIdleTimer({
    timeout: 60 * 60 * 1000, // 1 hour
    onIdle: handleOnIdle,
    debounce: 250,
  });

  useEffect(() => {
    const handleUserActivity = () => reset();

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("click", handleUserActivity);

    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("click", handleUserActivity);
    };
  }, [reset]);

  return (
    <>
      <Loader loading={loading} />
      <Suspense fallback={<Loader loading={true} />}>
        <Routes>
          <Route path={`${rootName}`} element={<Mainlayout />}>
            <Route path="/" element={<Navigate to={`${rootName}`} />} />
            {!isAuthenticate && (
              <Route path={`${rootName}`} index element={<Landing />} />
            )}
            <Route element={<PrivateRoute isAuthenticated={isAuthenticate} />}>
              {privateRoutesData?.map((item, idx) => {
                return (
                  <Route
                    index
                    key={idx}
                    path={`${rootName}${item.path}`}
                    element={item.component}
                  />
                );
              })}
              <Route path={`${rootName}`} index element={<Landing />} />
            </Route>
            <Route path="/legal" element={<Legal />} />
            <Route path="/amlPolicy" element={<AmlPolicy />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/help" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/trading/:market?" element={<Exchange />} />
            <Route path="/password-reset/:token" element={<ResetPassword />} />
            <Route element={<PublicRoute isAuthenticated={isAuthenticate} />}>
              {publicRoutesData?.map((item, idx) => {
                return (
                  <Route
                    key={idx}
                    index
                    path={`${rootName}${item?.path}`}
                    element={item?.component}
                  />
                );
              })}
            </Route>
          </Route>
        </Routes>
      </Suspense>

      {/* For Ranger */}
      {<Ranger />}

      <GoAlert />
    </>
  );
};

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, userIsLoading } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.loader);
  const isAuthenticate = !userIsLoading && data.state === "active";

  useEffect(() => {
    dispatch(getUser({ isNull: true }));
    if (i18next.languages[0] === "en-US") {
      localStorage.setItem("activeLan", i18next.languages[0].split("-")[0]);
    } else {
      localStorage.setItem("activeLan", i18next.languages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //fixed login screen showing some time
  if (userIsLoading === null) {
    return <Loader loading={true} />;
  }

  return (
    <AppContent
      isAuthenticate={isAuthenticate}
      data={data}
      loading={loading}
      navigate={navigate}
      dispatch={dispatch}
    />
  );
}
