import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./Feature/User/UserSlices.js";
import loaderSlice from "./Feature/Loader/loader.slice.js";
import loginReducer from "./Feature/Login/LoginSlice.js";
import alertReducer from "./Feature/Alert/AlertSlice.js";
import registerReducer from "./Feature/Register/RegisterSlice.js";
import settingReducer from "./Feature/Setting/SettingSlice.js";
import verifyAccountReducer from "./Feature/VerifyAccount/VerifyAccountSlice.js";
import commonReducer from "./Feature/Common/CommonSlice.js";
import walletReducer from "./Feature/Wallet/WalletSlice";
import addressReducer from "./Feature/Address/AddressSlice.js";
import buySellReducer from "./Feature/BuySell/BuySellSlice.js";
import transakReducer from "./Feature/TransakWidgets/TransakWidgetSlice.js";
import tradeReducer from "./Feature/Trade/TradeSlice.js";
import exchnageReducer from "./Feature/Exchange/Exchanges.slice.js";
import rangerReducer from "./Feature/Ranger/RangerSlice.js";
import recentTradesReducer from "./Feature/RecentTrades/RecentTradesSlice.js"
import depthReducer from "./Feature/Depth/DepthSlice.js"
import klineReducer from "./Feature/Kline/KlineSlice.js"
import filterReducer from "./Feature/Filters/FilterSlice.js";
import sumsubReducer from "./Feature/Sumsub/SumsubSlice.js"

export const rootReducer = combineReducers({
  loader: loaderSlice,
  alert: alertReducer,
  user: userReducer,
  login: loginReducer,
  register: registerReducer,
  setting: settingReducer,
  verifyAccount: verifyAccountReducer,
  common: commonReducer,
  wallet: walletReducer,
  address: addressReducer,
  exchange: exchnageReducer,
  buySell: buySellReducer,
  transakWidget: transakReducer,
  trades: tradeReducer,
  ranger: rangerReducer,
  recentTrades: recentTradesReducer,
  depth: depthReducer,
  kline: klineReducer,
  filters: filterReducer,
  documents: sumsubReducer
});
