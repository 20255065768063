import { createSlice } from "@reduxjs/toolkit";
import { getTrades, tradeHistory } from "../../Services/TradeService";

export const TradeSlice = createSlice({
    name: "trades",
    initialState: {
        list: [],
        tradeList: [],
        loading: false,
        tradeTotal: 0,
        tradesCount: 0,
        error: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(tradeHistory.pending, (state, { meta }) => {
                state.loading = true;
                state.error = false
                state.list = []
            })
            .addCase(tradeHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload.list;
                state.tradeTotal = action.payload.total
            })
            .addCase(tradeHistory.rejected, (state) => {
                state.loading = false;
                state.error = true
            })

            .addCase(getTrades.pending, (state) => {
                state.error = false;
                state.tradeList = [];
            })
            .addCase(getTrades.fulfilled, (state, action) => {
                state.loading = false;
                state.tradeList = action?.payload?.list;
                state.tradesCount = action?.payload?.total;
            })
            .addCase(getTrades.rejected, (state, action) => {
                state.error = true;
            })
    },
});

export default TradeSlice.reducer;
export const { } = TradeSlice.actions;

