import { createSlice } from "@reduxjs/toolkit";
import { initValidateTrans, sendTransakData } from "../../Services/transakWidgetService.js";

const TransakWidgetSlice = createSlice({
    name: "transakWidget",
    initialState: {
        loading: false,
        error: false,
        status: false,
        code: null,
        finalStatus: false
    },
    reducers: {
        setTransStatus: (state, action) => {
            state.status = action.payload
        },
        failedSendTransData: (state, action) => {
            state.code = null
            state.loading = false
        },
        setFinalStatus: (state, action) => {
            state.finalStatus = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initValidateTrans.pending, (state) => {
                state.loading = true;
                state.status = false;
                state.error = false
            })
            .addCase(initValidateTrans.fulfilled, (state, action) => {
                state.loading = false;
                state.code = action.payload;
                state.status = true;
            })
            .addCase(initValidateTrans.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
                state.status = false;
            })

            .addCase(sendTransakData.pending, (state) => {
                state.loading = true;
                state.code = null;
            })
            .addCase(sendTransakData.fulfilled, (state, action) => {
                state.loading = false;
                state.code = action.payload;
            })
            .addCase(sendTransakData.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
            })
    },
});

export const { failedSendTransData, setTransStatus, setFinalStatus } = TransakWidgetSlice.actions;

export default TransakWidgetSlice.reducer;
