import {
  Login,
  Register,
  ForgotPassword,
  VerifyAccount,
  Settings,
  Balance,
  DepositWithdrawTab,
  OpenOrders,
  OrderHistory,
  BuySell,
  AccountHistory,
  TradeHistory,
  Sumsub,
  DeleteComponent,
} from "./LazyRoute";

import { publicRouteObj, primaryRoutes } from "../interfaces/Routing.js";

// Public routing array

const { login, register, forgotPassword, verifyAccount, deleteRequest } = publicRouteObj;

const {
  settings,
  depositWithdraw,
  balance,
  openOrders,
  orderHistory,
  accountHistory,
  buysell,
  tradeHistory,
  sumsub
} = primaryRoutes;

export const publicRoutesData = [
  {
    path: login,
    component: <Login />,
  },
  {
    path: register,
    component: <Register />,
  },
  {
    path: forgotPassword,
    component: <ForgotPassword />,
  },
  {
    path: verifyAccount,
    component: <VerifyAccount />,
  },
  {
    path: deleteRequest,
    component: <DeleteComponent />,
  },
];

// Private routing array

export const privateRoutesData = [
  {
    path: settings,
    component: <Settings />,
  },
  {
    path: balance,
    component: <Balance />,
  },
  {
    path: depositWithdraw,
    component: <DepositWithdrawTab />,
  },
  {
    path: openOrders,
    component: <OpenOrders />,
  },
  {
    path: accountHistory,
    component: <AccountHistory />,
  },
  {
    path: orderHistory,
    component: <OrderHistory />
  },
  {
    path: tradeHistory,
    component: <TradeHistory />
  },
  {
    path: buysell,
    component: <BuySell />,
  },
  {
    path: sumsub,
    component: <Sumsub />,
  },

];
