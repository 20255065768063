import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion.js";
import { startLoading, stopLoading } from "../Feature/Index";
import { errorToastSelector } from "../Selector/ToastSelector";

export const userCreate = createAsyncThunk(
  "signup/userCreate",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { data: res, status: true, email: payload?.email, phone: payload?.phone_number };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
