export const truncateData = (num, fixed) => {
  let number;
  if (num === 0) {
    number = num.toFixed(fixed);
  } else {
    number = Number(num).toFixed(fixed);
  }

  if (isNaN(number) || number === undefined) {
    number = 0;
  }

  number = parseFloat(number)

  return number;
};

export const multy = (a, b) => {
  if (a === null || b === null) {
    return;
  }
  const tot = int_val(a) * int_val(b);
  return tot / (divider(a) * divider(b));
};

function divider(n) {
  const t = n.toString().split('.')[1];
  if (t) {
    return 10 ** t.length;
  } else {
    return 1;
  }
}

function int_val(n) {
  return parseInt(n.toString().split('.').join(''));
}

export const truncateData2 = (num, fixed) => {

  let number;
  if (num === 0) {
    number = Math.trunc(num * Math.pow(10, fixed)) / Math.pow(10, fixed);
  } else {
    number = Math.trunc(num * Math.pow(10, fixed)) / Math.pow(10, fixed);
  }

  if (isNaN(number) || number === undefined) {
    number = 0;
  }

  number = parseFloat(number)

  return number;
};