import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { startLoading, stopLoading } from "../Feature/Index";
import { getUser } from "./UserServices";
import { successToastSelector } from "../Selector/ToastSelector";

export const fetchLogout = createAsyncThunk(
    "logout/fetchLogout",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading(true));
            let url = `/identity/sessions`;
            await API.delete(config.barong)(url);
            sessionStorage.clear();
            localStorage.clear("csrfToken");
            await dispatch(getUser());
            dispatch(
                successToastSelector({ message: ['identity.session.logout'] }, "success")
            );
            dispatch(stopLoading(false));
            return true;
        } catch (e) {
            dispatch(stopLoading(false));
            return false;
        }
    }
);