import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTheme } from "../../redux/Feature/Index";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('ThemeMode') || 'dark');

  const toggleTheme = (value) => {
    const setVal = value ? "light" : "dark"
    localStorage.setItem('ThemeMode', setVal);
    setIsDarkMode(!value);
    dispatch(setTheme(setVal));
  };

  useEffect(() => {
    setIsDarkMode(localStorage.getItem('ThemeMode') || 'dark');
  }, []);

  const theme = localStorage.getItem('ThemeMode') || "dark";

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
