import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { errorToastSelector } from "../Selector/ToastSelector";

export const getMarkets = createAsyncThunk(
    "markets/getMarkets",
    async (payload, { dispatch }) => {
        try {
            let url = `public/markets`;
            const list = await API.get(config.peatio)(url);
            return list;
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const marketGetTickers = createAsyncThunk(
    "markets/marketGetTickers",
    async (payload, { dispatch }) => {
        try {
            let url = `/public/markets/tickers`;
            const tickers = await API.get(config.peatio)(url);
            if (tickers) {
                const pairs = Object.keys(tickers);
                const convertedTickers = pairs.reduce((result, pair) => {
                    result[pair] = tickers[pair].ticker;
                    return result;
                }, {});
                return convertedTickers
            }

        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const checkMarketLimit = createAsyncThunk(
    "markets/checkMarketLimit",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let url = `/market/check_limit/${payload.id}`;
            const data = await API.get(config.peatio)(url);
            return data.limit_exceeded

        } catch (e) {
            if (e.message[0] !== 'market.trade.not_permitted') {
                dispatch(errorToastSelector({ message: e.message, code: e.code, type: 'error' }));
            }
            return rejectWithValue(e);
        }
    }
);


