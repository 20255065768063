import { selectCurrencies } from "./currencies";
import { uppercase } from "../../helpers";
const selectMarketsState = (state) => state.exchange;

export const selectMarkets = (state) => selectMarketsState(state).marketList;

export const selectMarketsLoading = (state) =>
  selectMarketsState(state).loading;

export const selectCurrentMarket = (state) =>
  selectMarketsState(state).currentMarket;

export const selectPreviousMarket = (state) =>
  selectMarketsState(state).previousMarket;

export const selectMarketTickers = (state) =>
  selectMarketsState(state).marketTickers;
export const selectCurrentMarketTrades = (markets, currentCurrency) => {
  var trades = [];
  if (markets !== undefined) {
    trades = markets.filter((market) => market.base_unit === currentCurrency);
  }
  return trades;
};

export const getQuoteUnit = (markets, market_id) => {
  let market = markets.find((item) => item.id === market_id);
  if (market !== undefined) {
    return uppercase(market.quote_unit);
  } else {
    return "";
  }
};

export const getMarketName = (markets, market_id) => {
  let market = markets.find((item) => item.id === market_id);
  if (market !== undefined) {
    return market.name;
  } else {
    return "-";
  }
};

export const totalVolume = (state) => {
  const currencies = selectCurrencies(state);
  const markets = selectMarkets(state);
  const tickers = selectMarketTickers(state);

  if (
    tickers !== undefined &&
    currencies !== undefined &&
    markets !== undefined
  ) {
    const data = [];
    currencies.map((value) => {
      let markets_list = markets.filter((item) => item.quote_unit === value.id);
      if (markets_list !== undefined && markets_list !== []) {
        let volumeSum = 0.0;
        markets_list?.map((market) => {
          let ticker = tickers[market.id];
          if (ticker !== undefined) {
            volumeSum = volumeSum + parseFloat(ticker.total_volume);
          }
        });
        data.push({
          name: value.id,
          symbol: value.symbol,
          volume: volumeSum,
        });
      }
    });
    return data;
  }
};
