import { createSlice } from "@reduxjs/toolkit";
import { defaultStorageLimit } from "../../../api";
import { recentTradesFetch } from "../../Services/RecentTradesService";
import { convertTradeEventList } from "../../../helpers/recentTrades";

export const recentTradesSlice = createSlice({
    name: "recentTrades",
    initialState: {
        list: [],
        loading: false
    },
    reducers: {
        recentTradesPush: (state, action) => {

            const lastTrades = convertTradeEventList(
                action.payload.market,
                action.payload.trades
            );

            state.list = [...lastTrades, ...state.list].slice(0, defaultStorageLimit())
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(recentTradesFetch.pending, (state) => {
                state.error = false;
            })
            .addCase(recentTradesFetch.fulfilled, (state, action) => {
                state.list = action?.payload?.slice(0, defaultStorageLimit())
            })
            .addCase(recentTradesFetch.rejected, (state, action) => {
                state.error = true;
                state.list = []
            });
    },
});

export const {
    recentTradesPush
} = recentTradesSlice.actions;
export default recentTradesSlice.reducer;
