import { localeDate } from "./localeDate";
import { getTimezone } from "./timezone";

export const convertTradeEventToTrade = (market, trade) => {
  return {
    market,
    id: trade.tid,
    created_at: localeDate(trade.date, getTimezone(), ''),
    taker_type: trade.taker_type,
    type: trade.type,
    price: String(trade.price),
    amount: String(trade.amount),
    funds: String(Number(trade.price) * Number(trade.amount))
  };
};

export const convertTradeEventList = (market, trades) => {
  return trades.map(trade => convertTradeEventToTrade(market, trade));
};
