import { rootReducer } from "./RootReducer";
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

const middleware = [thunk];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
