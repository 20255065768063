import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { setTransStatus, startLoading, stopLoading } from "../Feature/Index";
import { buildQueryString } from "../../helpers";
import { fetchBuySellHistory } from "./BuySellServices";
import { failedSendTransData, setFinalStatus } from "../Feature/TransakWidgets/TransakWidgetSlice";

export const initValidateTrans = createAsyncThunk(
    "transakWidget/initValidateTrans",
    async (payload, { rejectWithValue, dispatch }) => {

        const type = payload.isBuyOrSell;
        const { t } = payload
        delete payload.t

        try {
            dispatch(startLoading());
            let url = `/account/transak_validation?${buildQueryString(payload)}`;
            const data = await API.get(config.peatio)(url);
            if (data.status === 400) {
                dispatch(
                    successToastSelector({
                        message: [data.message], code: data.code, type: "error"
                    })
                );
                return;
            }
            dispatch(setTransStatus(true));
            dispatch(stopLoading());
            return data;
        } catch (e) {
            dispatch(stopLoading());
            let minAmount = e.message[0]?.split(",")[0]?.replace("minAmount:", "");
            let maxAmount = e.message[0]?.split(",")[1]?.replace("maxAmount:", "");

            let errorMessage;

            switch (true) {
                case type === "SELL" && e.message[0]?.split(":")[0] === "minAmount":
                    errorMessage = `${t("buy_sell.error.greater")} ${e.message[0]?.split(":")[1]}`;
                    break;

                case type === "SELL" && e.message[0]?.split(":")[0] === "maxAmount":
                    errorMessage = `${t("buy_sell.error.less")} ${e.message[0]?.split(":")[1]}`;
                    break;

                default:
                    errorMessage = `${t("buy_sell.error.in_between")} ${minAmount} ${maxAmount ? `${t("buy_sell.error.and")} ${maxAmount}` : ''}`;
            }

            const errorType = (type === "SELL" || e.code === 400) ? "error" : "error";

            dispatch(errorToastSelector({
                message: [errorMessage],
                code: e.code,
                type: errorType
            }));

            dispatch(setTransStatus(false));
            // dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const sendTransakData = createAsyncThunk(
    "transakWidget/sendTransakData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/tranask`;
            let data = await API.post(config.peatio)(url, payload);
            dispatch(stopLoading());
            switch (payload.status) {
                case "PAYMENT_DONE_MARKED_BY_USER":
                    dispatch(
                        successToastSelector({
                            message: ["account.transak.payment_done"],
                            code: data.code,
                            type: "success",
                        })
                    );

                    break;
                case "PROCESSING":
                    dispatch(
                        successToastSelector({
                            message: ["account.transak.processing"],
                            code: data.code,
                            type: "success",
                        })
                    );
                    break;
                case "PENDING_DELIVERY_FROM_TRANSAK":
                    dispatch(
                        successToastSelector({
                            message: ["account.transak.processing"],
                            code: data.code,
                            type: "success",
                        })
                    );
                    break;
                default:
                    break;
            }
            dispatch(fetchBuySellHistory({
                transak_type: payload.transak_type,
                currency: payload.crypto_currency,
                network: payload.network,
            }))
            dispatch(setFinalStatus(true))
            return data;
        } catch (e) {
            dispatch(stopLoading());
            dispatch(failedSendTransData());
            dispatch(setTransStatus(false))
            if (e.message !== "Merchant has already been taken") {
                dispatch(errorToastSelector({
                    message: [e.message], code: e.code, type: 'error'
                }));
            }
            else {
                dispatch(errorToastSelector(e, "error"));
            }
            return rejectWithValue(e);
        }
    }
);