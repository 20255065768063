export const preciseData = (data, precision = 0) => {
  return data ? Number(data).toFixed(precision) : data;
};
export const percentWidth = (maxVal, val) => {
  return Number((val * 100.0) / maxVal).toFixed(2);
};

export const maxData = (data) => {
  let tot_arr = data.map(bigData);
  return Math.max(...tot_arr);
};

function bigData(data){
  return parseFloat(Number(parseFloat(data[0]) * parseFloat(data[1])).toFixed(8));
};

