export const depositeOption = [
  { text: "ALL", value: "all" },
  { text: "COLLECTED", value: "collected" },
  { text: "SUBMITTED", value: "submitted" },
  { text: "CANCELED", value: "canceled" },
  { text: "REJECTED", value: "rejected" },
  // { text: "COMPLETED", value: "completed" }
];

export const withdrawOption = [
  { text: "ALL", value: "all" },
  { text: "CONFIRMING", value: "confirming" },
  { text: "SUCCEED", value: "succeed" },
  { text: "PROCESSING", value: "processing" },
  { text: "REJECTED", value: "rejected" },
  { text: "FAILED", value: "failed" },
  { text: "CANCELED", value: "canceled" },
  { text: "ACCEPTED", value: "accepted" },
  { text: "PENDING", value: "prepared" },
];


export const buySellOption = [
  { text: "ALL", value: "all" },
  { text: "COMPLETED", value: "completed" },
  { text: "PENDING", value: "pending" },
  { text: "CANCELLED", value: "cancelled" },
  { text: "FAILED", value: "failed" },
];
