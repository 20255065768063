import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { errorToastSelector } from "../Selector/ToastSelector";
import { startLoading, stopLoading } from "../Feature/Index";
import { buildQueryString } from "../../helpers";

export const fetchBuySellHistory = createAsyncThunk(
    "buySell/fetchBuySellHistory",
    async ({ params, filterData }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/account/tranask?${params !== undefined ? buildQueryString(params) : ''}&${filterData !== undefined
                ? buildQueryString(filterData)
                : ''
                }`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchBuySellData = createAsyncThunk(
    "buySell/fetchBuySellData",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `account/transak/support_data?${buildQueryString(
                payload
            )}`;
            const data = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return data;
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);