import React from 'react';
import { cryptoIconUrl } from '../../helpers';
import { selectCurrentMarket } from './markets';
import { Tooltip } from 'antd';
export const selectCurrencies = (state) => state.common.currencyList;

export const baseCurrency = (state) => {
  const currentMarket = selectCurrentMarket(state);
  const currencies = selectCurrencies(state);
  return currentMarket && currencies
    ? currencies.find((item) => item.id === currentMarket.base_unit)
    : {};
};

export const quoteCurrency = (state) => {
  const currentMarket = selectCurrentMarket(state);
  const currencies = selectCurrencies(state);

  return currentMarket && currencies
    ? currencies.find((item) => item.id === currentMarket.quote_unit)
    : {};
};

export const currenciesDropDown = (state) => {
  var currencies = selectCurrencies(state);
  return currencies.map((currency, index) => ({
    key: index,
    text: currency.id,
    value: currency.id,
    type: currency.type,
    deposit_fee: currency.deposit_fee,
    swap_fees: currency.swap_fees,
    networks: currency.networks,
    precision: currency.precision,
    image: {
      avatar: true,
      src: cryptoIconUrl(currency.id, currency.icon_url),
    },
  }));
};

export const currenciesDropObj = (curr) => {
  return curr.map((currency, index) => ({
    key: index,
    text: currency.id,
    value: currency.id,
    type: currency.type,
    deposit_fee: currency.deposit_fee,
    swap_fees: currency.swap_fees,
    networks: currency.networks,
    precision: currency.precision,
    price: currency?.price,
    image: {
      avatar: true,
      src: cryptoIconUrl(currency.id, currency.icon_url),
    },
  }));
};

export const buySellDropdown = (curr) => {

  return curr.map((item, index) => ({
    key: index,
    text: item.currency,
    value: item.currency,
    type: item.type,
    networks: item.network_info,
    precision: item.currency_precision,
    image: {
      avatar: true,
      src: item.type === 'coin' ? cryptoIconUrl(item.currency, item.icon) : `data:image/svg+xml,${encodeURIComponent(item.icon)}`,
    },
    payment_details: item.payment_details
  }));
};

export const getFirstNetwork = (networks) => {
  let newArr = [];
  if (networks) {
    let list = networks?.map((val, idx) => {
      let data = {
        value: val?.blockchain_key,
        text: val?.blockchain_key.split("-")[0],
      };
      newArr.push(data);
    });
  }
  return newArr
}

export const getCurrencyName = (currencies, id) => {
  var currency = currencies.filter((curr) => curr.id === id);
  var name = '';

  if (currency.length > 0) {
    name = currency[0].name;
  }

  return name;
};

export const getCurrencyType = (currencies, id) => {
  var currency = currencies.filter((curr) => curr.id === id);
  var type = '';

  if (currency.length > 0) {
    type = currency[0].type;
  }

  return type;
};

export const getPrecision = (currencies, id) => {
  let currency = currencies.filter((curr) => curr.id === id);
  let precision = '';

  if (currency.length > 0) {
    precision = currency[0].precision;
  }

  return precision;
};

export const explorerTransactionUrl = (
  currencies,
  currency_id,
  txid = '-',
  blockchain_key,
  transaction_type
) => {
  let transaction_url = '#';
  let transaction_link = '-';
  for (let i = 0; i < currencies.length; i++) {
    if (currencies[i].id.toUpperCase() === currency_id?.toUpperCase()) {
      let res = currencies[i]?.networks?.map((val, idx) => {
        if (val.blockchain_key === blockchain_key) {
          transaction_url = val.explorer_transaction?.replace('#{txid}', txid);
        }
        return val;
      });
      break;
    }
  }
  if (txid && txid !== '-' && transaction_url !== '#' && !transaction_type) {
    transaction_link = (
      <>
        <Tooltip placement="top" title={txid} >
          <a href={transaction_url} target="_blank" rel="noopener noreferrer">
            {txid?.length > 50 ? txid?.substring(0, 30) + "..." : txid}
          </a>
        </Tooltip>
      </>
    );
  } else {
    transaction_link = (
      <Tooltip placement="top" title={txid} >
        {txid?.length > 50 ? txid?.substring(0, 30) + "..." : txid}
      </Tooltip>
    );
  }

  return transaction_link;
};

export const explorerAddressUrl = (currencies, currency_id, address) => {
  let url = null;
  let currency = currencies.find((item) => item.id === currency_id);
  if (currency && currency.explorer_address !== undefined) {
    url = currency.explorer_address.replace('#{address}', address);
  }
  return url;
};

export const getIconUrl = (currencies, id) => {
  let url = null;

  let currency = currencies.find((item) => item.id === id);
  if (currency && currency.icon_url !== undefined) {
    url = currency.icon_url;
  }
  return url;
};

export const getNetworks = (currencies, id) => {
  const checkNetworks = currencies.filter(items => items.id === id)
  return checkNetworks[0]?.networks[0]?.blockchain_key
};


export const buySellUrl = (
  currencies,
  currency_id,
  txid = '-',
  blockchain_key,
  transaction_type
) => {
  let transaction_url = '#';
  let transaction_link = '-';
  for (let i = 0; i < currencies.length; i++) {
    if (currencies[i].id.toUpperCase() === currency_id?.toUpperCase()) {
      let res = currencies[i]?.networks?.map((val, idx) => {
        if (val.blockchain_key.split("-")[0] === blockchain_key && txid !== "DUMMY_TX_ID") {
          transaction_url = val.explorer_transaction?.replace('#{txid}', txid);
        }
        return val;
      });
      break;
    }
  }
  if (txid && txid !== '-' && transaction_url !== '#' && !transaction_type) {
    transaction_link = (
      <a href={transaction_url} target="_blank" rel="noopener noreferrer">
        <Tooltip placement="top" title={txid} >
          <a href={transaction_url} target="_blank" rel="noopener noreferrer">
            {txid?.length > 50 ? txid?.substring(0, 30) + "..." : txid}
          </a>
        </Tooltip>
      </a>
    );
  } else {
    transaction_link = (
      <Tooltip placement="top" title={txid} >
        {txid?.length > 50 ? txid?.substring(0, 30) + "..." : txid}
      </Tooltip>
    );
  }

  return transaction_link;
};
