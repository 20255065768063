import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../Feature/Index";
import {
    errorToastSelector,
} from "../Selector/ToastSelector";
import { API } from "../../api";

export const recentTradesFetch = createAsyncThunk(
    "recentTrades/recentTradesFetch",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const market = payload;

            if (!market.id) {
                throw new Error('ERROR: Empty market provided to recentTradesFetchSaga');
            }
            dispatch(startLoading());
            let url = `/public/markets/${market.id}/trades`
            const trades = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return trades;
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);
