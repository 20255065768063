import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { errorToastSelector } from "../Selector/ToastSelector";
import { startLoading, stopLoading } from "../Feature/Index";
import { buildQueryString } from "../../helpers";

export const tradeHistory = createAsyncThunk(
    "trades/tradeHistory",
    async ({ params, filterData }, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/market/trades?${params !== undefined ? buildQueryString(params) : ''}&${filterData !== undefined
                ? buildQueryString(filterData)
                : ''
                }`;
            const { data, headers } = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, total: headers.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getTrades = createAsyncThunk(
    "exchangeService/getTrades",
    async (payload, { rejectWithValue, dispatch }) => {
        const { pageName } = payload
        delete payload.pageName
        try {
            let url;
            dispatch(startLoading(true));
            if (payload?.id) {
                url = `public/markets/${payload?.id}/trades`;
            } else {
                url = `/market/trades?${buildQueryString(payload)}`;
            }
            let res = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading(false));
            return {
                list: res?.data,
                total: res?.headers?.total > 0 ? res?.headers?.total : 0,
                status: true,
            };
        } catch (e) {
            dispatch(stopLoading(false));
            if (pageName !== "exchange") {
                dispatch(errorToastSelector(e));
            }
            return rejectWithValue(e);
        }
    }
);

