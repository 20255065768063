export const publicRouteObj = {
  login: "/login",
  register: "/register",
  verifyAccount: "/verify-account",
  forgotPassword: "/forgot-password",
  legal: "/legal",
  deleteRequest: "/delete-request"
};

export const primaryRoutes = {
  settings: "/settings",
  balance: "/balance",
  depositWithdraw: "/depositWithdraw",
  openOrders: "/openOrders",
  orderHistory: "/orderHistory",
  accountHistory: "/transactionHistory",
  buysell: "/buysell",
  tradeHistory: "/tradeHistory",
  sumsub: "/sumsub-kyc",
};
