import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading, verifyUser } from "../Feature/Index";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { buildQueryString } from "../../helpers";

export const forgotPassword = createAsyncThunk(
    "setting/forgotPassword",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity/users/password/generate_code`;
            await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: ['password.reset_link'],
                })
            );
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const changePass = createAsyncThunk(
    "setting/createResetPass",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity/users/password/confirm_code`;
            await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: ['change_password.success'],
                })
            );
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const verifyToken = createAsyncThunk(
    "setting/verifyToken",
    async (payload, { dispatch }) => {
        try {
            let url = `identity/users/password/link_expire`;
            await API.post(config.barong)(url, payload);
            return { status: true };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const changePassword = createAsyncThunk(
    "setting/changePassword",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/users/password`;
            const res = await API.put(config.barong)(url, payload);
            dispatch(
                successToastSelector({
                    message: ['change_password.success'],
                })
            );
            dispatch(stopLoading());
            return { data: res, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getOtpCode = createAsyncThunk(
    "setting/getOtpCode",
    async ({ otpClicked, ...rest }, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/users/password/otp_confirmation`;
            await API.get(config.barong)(url, rest);
            dispatch(
                successToastSelector({
                    message: `OTP ${otpClicked ? "resent" : "sent"} successfully`,
                })
            );
            dispatch(stopLoading());
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getLastLogin = createAsyncThunk(
    "setting/getLastLogin",
    async (payload, { dispatch }) => {
        try {
            let url = `resource/users/activity/session?limit=1`;
            await API.get(config.barong)(url);
            return { status: true };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const verifyQrCode = createAsyncThunk(
    "setting/verifyQrCode",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/otp/enable`;
            await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: ['resource.otp.enabled'],
                })
            );
            dispatch(verifyUser({
                otp: true
            }))
            return {
                success: true,
                statusCode: 200,
                verifyAction: 'enable',
            };

        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const generateQrCode = createAsyncThunk(
    "setting/generateQrCode",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/otp/generate_qrcode`;
            let res = await API.post(config.barong)(url);
            dispatch(stopLoading());
            return { qrCodeData: res, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const disableGoogleAuth = createAsyncThunk(
    "setting/disableGoogleAuth",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/otp/disable`;
            await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: ['resource.otp.disable'],
                })
            );
            return {
                success: true,
                statusCode: 200,
                verifyAction: 'disable',
            };

        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return { error: true, statusCode: 400 };
        }
    }
);

export const fetchFeeCurrencyCheck = createAsyncThunk(
    "setting/fetchFeeCurrencyCheck",
    async (payload, { dispatch }) => {
        try {
            let url = `account/me`;
            let res = await API.get(config.peatio)(url);
            return { values: res };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const updateFeeCurrencyCheck = createAsyncThunk(
    "setting/updateFeeCurrencyCheck",
    async (payload, { dispatch }) => {
        try {
            let url = `account/update/portfolio?${buildQueryString(payload)}`;
            let res = await API.get(config.peatio)(url, payload);
            return { values: res };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);


