import { createSlice } from "@reduxjs/toolkit";
import { RangerFavMarketUpdate, getRangerFavMarketUpdate } from "../../Services/RangerService";


export const rangerSlice = createSlice({
    name: "ranger",
    initialState: {
        withAuth: false,
        connected: false,
        subscriptions: [],
        fav_Markets: [],
        fav_Markets_list: [],
    },
    reducers: {
        rangerConnectData: (state, action) => {
            state.connected = action.payload
        },
        subscriptionsUpdate: (state, action) => {
            state.subscriptions = [...action.payload.subscriptions]
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getRangerFavMarketUpdate.pending, (state) => {

            })
            .addCase(getRangerFavMarketUpdate.fulfilled, (state, action) => {
                state.fav_Markets = action.payload;
            })
            .addCase(getRangerFavMarketUpdate.rejected, (state, action) => {

            })

            .addCase(RangerFavMarketUpdate.pending, (state) => {

            })
            .addCase(RangerFavMarketUpdate.fulfilled, (state, action) => {
                state.fav_Markets_list = [action.payload.data, ...state.fav_Markets_list]
            })
            .addCase(RangerFavMarketUpdate.rejected, (state, action) => {

            });
    },
});

export const {
    subscriptionsUpdate,
    rangerConnectData
} = rangerSlice.actions;
export default rangerSlice.reducer;
