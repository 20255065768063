import { createSlice } from "@reduxjs/toolkit";
import { changeStateBeneficiary, deleteBeneficiary, fetchBeneficiary, fetchDeposit, fetchWithdraw } from "../../Services/Wallet";

export const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        selectedTab: "Deposit",
        selectedCoin: null,
        success: false,
        otpSuccess: false,
        withDrawList: [],
        withDrawTotal: 0,
        depositList: [],
        depositTotal: 0,
        beneList: [],
        remBeneficiaryModal: false,
        benePopup: false,
    },
    reducers: {
        setCurrentTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setSelectedCoin: (state, action) => {
            state.selectedCoin = action.payload
        },
        resetSelectedCoin: (state, action) => {
            state.selectedCoin = null
        },
        successResponse: (state, action) => {
            state.success = action.payload;
            state.otpSuccess = false
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWithdraw.pending, (state, { meta }) => {
                state.error = false;
                state.withDrawList = []
            })
            .addCase(fetchWithdraw.fulfilled, (state, action) => {
                state.withDrawList = action.payload.list;
                state.withDrawTotal = action.payload.total
            })
            .addCase(fetchWithdraw.rejected, (state) => {
                state.error = true;
                state.withDrawList = []
            })

            .addCase(fetchDeposit.pending, (state, { meta }) => {
                state.error = false;
                state.depositList = []
            })
            .addCase(fetchDeposit.fulfilled, (state, action) => {
                state.depositList = action.payload.list;
                state.depositTotal = action.payload.total
            })
            .addCase(fetchDeposit.rejected, (state) => {
                state.error = true;
                state.depositList = []
            })

            .addCase(fetchBeneficiary.pending, (state, { meta }) => {
                state.error = false;
                state.beneList = []
            })
            .addCase(fetchBeneficiary.fulfilled, (state, action) => {
                state.beneList = action.payload.list;
            })
            .addCase(fetchBeneficiary.rejected, (state) => {
                state.error = true;
                state.beneList = []
            })

            .addCase(deleteBeneficiary.pending, (state, { meta }) => {
                state.error = false;
                state.remBeneficiaryModal = false;
            })
            .addCase(deleteBeneficiary.fulfilled, (state, action) => {
                state.beneList = state.beneList.filter(
                    (beneficiary) => beneficiary.id !== action.payload.id
                );
                state.remBeneficiaryModal = true;
            })
            .addCase(deleteBeneficiary.rejected, (state) => {
                state.error = true;
                state.remBeneficiaryModal = false;
            })

            .addCase(changeStateBeneficiary.pending, (state, { meta }) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(changeStateBeneficiary.fulfilled, (state, action) => {
                let newState = [...state.beneList];
                let index = newState.findIndex(
                    (beneList) => beneList.id === action.payload.changeData.id
                );
                if (index !== -1) newState[index] = action.payload.changeData;
                return {
                    ...state,
                    beneList: newState,
                    benePopup: action.payload.success,
                    loading: false,
                };
            })
            .addCase(changeStateBeneficiary.rejected, (state) => {
                state.error = true;
                state.loading = false;
            });
    },
});

export default walletSlice.reducer;
export const {
    setCurrentTab,
    setSelectedCoin,
    resetSelectedCoin,
    successResponse }
    = walletSlice.actions;

