import { lazy } from "react";

export const Login = lazy(() => import("../components/Login/Login.jsx"));
export const Register = lazy(() =>
  import("../components/Register/Register.jsx")
);
export const Legal = lazy(() => import("../components/FooterComponents/Legal/Legal.jsx"))
export const AmlPolicy = lazy(() => import("../components/FooterComponents/AmlPolicy/AmlPolicy.jsx"))
export const Privacy = lazy(() => import("../components/FooterComponents/Privacy.jsx"))
export const Terms = lazy(() => import("../components/FooterComponents/Terms.jsx"))
export const Faq = lazy(() => import("../components/Faq/Faq.jsx"));
export const PageNotFound = lazy(() => import("../components/PageNotFound/PageNotFound.jsx"));
export const MenuBar = lazy(() => import("../components/Layout/Header/Menubar.jsx"))

export const ForgotPassword = lazy(() =>
  import("../components/ForgotPassword/ForgotPassword.jsx")
);
export const VerifyAccount = lazy(() =>
  import("../components/VerifyAccount/VerifyAccount.jsx")
);
export const Settings = lazy(() =>
  import("../components/Setting/Settings.jsx")
);
export const Mainlayout = lazy(() =>
  import("../components/Layout/Mainlayout.jsx")
);
export const Balance = lazy(() => import("../components/Balance/Balance.jsx"));
export const Contact = lazy(() => import("../components/Contact/Contact.jsx"));
export const DepositWithdrawTab = lazy(() =>
  import("../components/DepositWithdrawTab/DepositWithdrawTab.jsx")
);
export const OpenOrders = lazy(() =>
  import("../components/History/OpenOrders.jsx")
);
export const AccountHistory = lazy(() => import("../components/History/Account.jsx"));
export const OrderHistory = lazy(() => import("../components/History/OrderHistory.jsx"));
export const TradeHistory = lazy(() => import("../components/History/TradeHistory.jsx"));
export const Exchange = lazy(() => import("../components/AdvanceTrade/AdvanceExchange.jsx"));
export const ResetPassword = lazy(() => import("../components/ResetPassword/ResetPassword.jsx"));
export const BuySell = lazy(() =>
  import("../components/BuySell/BuySellTabs.jsx")
);
export const Sumsub = lazy(() => import("../components/Sumsub/Sumsub.jsx"))
export const DeleteComponent = lazy(() => import("../components/DeleteComponent/DeleteComponent.jsx"))


export const Landing = lazy(() => import("../components/Landing/Landing.jsx"));
