import { createSlice } from "@reduxjs/toolkit";
import { checkMarketLimit, getMarkets, marketGetTickers } from "../../Services/MarketService";

export const MarketSlice = createSlice({
    name: "markets",
    initialState: {
        list: [],
        currentMarket: undefined,
        currentMarketId: "",
        tickers: {},
        previousMarket: undefined,
        tickerLoading: false,
        loading: false,
        actualList: [],
        favMarkets: [],
        actualFavMarkets: [],
        marketList: [],
        marketLimit: {
            loading: false,
            success: false,
            error: false,
            exceeded: null,
        },
    },
    reducers: {

        setUnsetFavourite: (state, action) => {

        },

        setMarketTickers: (state, action) => {
            state.tickers = action.payload;
        },

        // setCurrentMarket: (state, action) => {
        //     state.currentMarket = action.payload.currentMarket;
        //     state.currentMarketId = action.payload.currentMarketId;
        // },

        setPreviousMarket: (state, action) => {
            state.previousMarket = action.payload
        },



        resetCurrentMarket: (state, action) => {
            state.currentMarket = undefined;
            state.currentMarketId = "";
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getMarkets.pending, (state, { meta }) => {

            })
            .addCase(getMarkets.fulfilled, (state, action) => {
                let favMarkets = [];
                let favMarketsId = JSON.parse(localStorage.getItem('favMarketsId'));
                action.payload.forEach((market, index) => {
                    if (
                        favMarketsId != null &&
                        favMarketsId.length > 0 &&
                        favMarketsId.includes(market.id)
                    ) {
                        action.payload[index]['isFav'] = 1;
                        favMarkets.push(action.payload[index]);
                    } else {
                        action.payload[index]['isFav'] = 0;
                    }
                });
                return {
                    ...state,
                    list: action.payload,
                    marketList: action.payload,
                    loading: false,
                    favMarkets: favMarkets,
                };
            })
            .addCase(getMarkets.rejected, (state) => {
                state.error = true;

            })

            .addCase(marketGetTickers.pending, (state, { meta }) => {
                state.tickerLoading = true;
                state.tickers = {}
            })
            .addCase(marketGetTickers.fulfilled, (state, action) => {
                state.tickerLoading = false
                state.tickers = action.payload
            })
            .addCase(marketGetTickers.rejected, (state) => {
                state.tickerLoading = false
            })

            .addCase(checkMarketLimit.pending, (state, { meta }) => {
                state.marketLimit = {
                    loading: true,
                    success: false,
                    error: false,
                    exceeded: null,
                }
            })
            .addCase(checkMarketLimit.fulfilled, (state, action) => {
                state.marketLimit = {
                    loading: false,
                    success: true,
                    error: false,
                    exceeded: action.payload,
                }
            })
            .addCase(checkMarketLimit.rejected, (state) => {
                state.marketLimit = {
                    loading: false,
                    success: false,
                    error: true,
                    exceeded: null,
                }
            })


    },
});

export default MarketSlice.reducer;
export const { setPreviousMarket, resetCurrentMarket } = MarketSlice.actions;

