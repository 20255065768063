import { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { message } from "antd";
import { showAlert } from "../redux/Feature/Index";

function GoAlert() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const alertProps = {
    message: useSelector((state) => state.alert.message),
    code: useSelector((state) => state.alert.code),
    type: useSelector((state) => state.alert.type),
  };

  // for Alert

  useEffect(() => {
    if (alertProps.message && alertProps.message.length) {
      goAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  message.config({
    maxCount: 1,
    duration: 2,
  });

  const goAlert = () => {
    let type = alertProps.type;
    _.forEach(alertProps.message, function (value) {
      if (type === "error") {
        message.error(t(value));
        if (value[0] === "authz.invalid_session") {
          setTimeout(() => {
            // window.location.reload();
          }, 500);
        }
      } else {
        message.success(t(value));
      }
      //empty messages state
      dispatch(
        showAlert({
          message: "",
          type: "",
          code: "",
        })
      );
    });
  };

  // return <ToastContainer autoClose="2000" />;
}
export default withTranslation()(GoAlert);
