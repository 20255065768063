import { createSlice } from "@reduxjs/toolkit";
import { klineFetch } from "../../Services/KlineService";
import { klineArrayToObject } from "../../../helpers/ranger";

export const klineSlice = createSlice({
    name: "kline",
    initialState: {
        last: undefined,
        marketId: undefined,
        period: undefined,
        loading: false,
        data: []
    },
    reducers: {
        klinePush: (state, action) => {
            const { kline, marketId, period } = action.payload;
            state.marketId = marketId;
            state.period = period;
            state.last = klineArrayToObject(kline)
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(klineFetch.pending, (state) => {
                state.error = false;
                state.loading = true
            })
            .addCase(klineFetch.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(klineFetch.rejected, (state, action) => {
                state.error = true;
                state.loading = false
            })
    },
});

export const {
    klinePush
} = klineSlice.actions;
export default klineSlice.reducer;
