import { message } from "antd";

export const copytoClipBoard = (param, t) => {
  navigator.clipboard.writeText(param).then(
    () => {
      message.success(t('coppied'));
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
    }
  );
};
