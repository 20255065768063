import copyCode from "../assets/images/copyCode.svg";
import profile from "../assets/images/profile.png";
import infoIcon from "../assets/images/infoIcon.svg";
import bannerImage from "../images/EvoImg/bannerImage.svg";
import Vector from "../assets/images/Vector.svg";
import transactionminus from "../images/EvoImg/transactionminus.svg";
import emailvar from "../images/EvoImg/emailvar.svg";
import password from "../assets/images/password.svg";
import googleAuth from "../images/EvoImg/googleAuth.svg";
import GooglePLay from "../assets/images/GooglePLay.svg";
import AppleStore from "../assets/images/AppleStore.svg";
import emailsetting from "../images/EvoImg/emailsetting.svg";
import btc from "../assets/images/btc.svg";
import phonesetting from "../images/EvoImg/phonesetting.svg";
import lytprofile from "../images/EvoImg/lytprofile.svg";
import securitywhite from "../images/EvoImg/securitywhite.svg";
import email from "../assets/images/email.svg";
import emperorconlogo from "../assets/images/emperorconlogo.svg";
import darktoplogo from "../assets/images/darktoplogo.svg";
import darkBottomlogo from "../assets/images/darkBottomlogo.svg";
import facebk from "../assets/images/facebk.svg";
import ins from "../assets/images/ins.svg";
import tw from "../assets/images/tw.svg";
import dis from "../assets/images/dis.png";
import tele from "../assets/images/tele.svg";
import Eth from "../assets/images/Eth.svg";
import store1 from "../assets/images/store1.svg";
import store2 from "../assets/images/store2.svg";
import approcess from "../assets/images/approcess.svg";
import bottomLogo from "../assets/images/bottomLogo.svg";
import DownArrow from "../images/DownArrow.svg";
import copyICON from "../assets/images/copyICON.png";
import qrCode from "../assets/images/qrCode.svg";
import ApplyBg from "../assets/images/ApplyBg.svg";
import managecard from "../assets/images/managecard.svg";
import mypin from "../assets/images/mypin.svg";
import whitelogo from "../assets/images/whitelogo.svg";
import masterIcon from "../assets/images/masterIcon.svg";
import download from "../assets/images/download.svg";
import cardverf from "../images/EvoImg/cardverf.svg";
import Tradecrypto from "../assets/images/Tradecrypto.svg";
import Buildcrypto from "../assets/images/Buildcrypto.svg";
import transactions from "../assets/images/transactions.svg";
import Easysignup from "../assets/images/Easysignup.svg";
import Engine from "../assets/images/Engine.svg";
import Secure from "../assets/images/Secure.svg";
import Highliquidity from "../assets/images/Highliquidity.svg";
import Quickpayments from "../assets/images/Quickpayments.svg";
import Multiasset from "../assets/images/Multiasset.svg";
import Multichain from "../assets/images/Multichain.svg";
import plusicon from "../assets/images/plusicon.svg";

export const assetsLink = {
  copyCode: copyCode,
  profileImg: profile,
  infoIcon: infoIcon,
  banner: bannerImage,
  profileBtn: Vector,
  securityBtn: transactionminus,
  verifyAccountIcon: emailvar,
  passWordIcon: password,
  googleAuthIcon: googleAuth,
  emailAccountIcon: emailsetting,
  coinsbtc: btc,
  phoneAuth: phonesetting,
  profilelyt: lytprofile,
  securityWhite: securitywhite,
  emailIcon: email,
  emperorconlogo: emperorconlogo,
  darktoplogo: darktoplogo,
  darkBottomlogo: darkBottomlogo,
  facebk: facebk,
  inst: ins,
  twit: tw,
  tele: tele,
  BtcImg: btc,
  EthImg: Eth,
  AppleStore: AppleStore,
  GooglePLay: GooglePLay,
  discard: dis,

  addFund: require("../assets/images/addFund.png"),
  support: require("../assets/images/support.png"),
  lowCost: require("../assets/images/lowCost.png"),
  trans: require("../assets/images/trans.png"),
  signUp: require("../assets/images/signUp.png"),
  googlePlay: store1,
  appleStore: store2,
  approcess: approcess,
  logoEmperorbottom: bottomLogo,
  fullemperor: emperorconlogo,
  empty: require("../images/empty.png"),
  downArrow: DownArrow,
  copyICON: copyICON,
  qrICON: qrCode,
  copyICONPNG: copyCode,
  starYellow: require("../assets/images/starImg.png"),
  star: require("../images/star.png"),
  crcIcon: require("../assets/images/crncIcon.png"),
  evotwoCard: ApplyBg,
  manage: managecard,
  pin: mypin,
  whitelogo: whitelogo,
  masterIcon: masterIcon,
  uiw_setting: require("../images/uiw_setting.png"),
  euroIcon: require("../assets/images/euroIcon.png"),
  download: download,
  check: require("../images/check.png"),
  cardVer: cardverf,
  tradecrypto: Tradecrypto,
  buildcrypto: Buildcrypto,
  transactions: transactions,
  easysignup: Easysignup,
  about: require("../assets/images/about.png"),
  RocketImg: require("../assets/images/RocketImg.png"),
  Engine: Engine,
  Secure: Secure,
  Highliquidity: Highliquidity,
  Quickpayments: Quickpayments,
  Multiasset: Multiasset,
  Multichain: Multichain,
  FuzzyBeginnings: require("../assets/images/FuzzyBeginnings.png"),
  // logoEvo:require('../assets/images/FuzzyBeginnings.png')
  plusicon: plusicon,
};
