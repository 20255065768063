import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../Feature/Index";
import {
    errorToastSelector
} from "../Selector/ToastSelector";
import { API } from "../../api";

export const depthFetch = createAsyncThunk(
    "depth/depthFetch",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading(true));
            if (payload?.id) {
                let url = `/public/markets/${payload?.id}/depth`;
                let res = await API.get(config.peatio)(url);
                dispatch(stopLoading(false));
                return { list: res, status: true };
            }
        } catch (e) {
            dispatch(stopLoading(false));
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);