import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { rangerUrl } from "../api";
import { isAuthenticated } from "../redux/Selector/User";

import {
  rangerConnectData,
  setKlineData,
  setMarketTickers,
  setOrderBook,
  setTrades,
} from "../redux/Feature/Index";
import {
  formatTicker,
  generateSocketURI,
  streamsBuilder,
} from "../helpers/ranger";
import { getRangerFavMarketUpdate } from "../redux/Services/RangerService";
import { recentTradesPush } from "../redux/Feature/RecentTrades/RecentTradesSlice";
import { selectCurrentMarket } from "../redux/Selector/markets";
import { store } from "../redux/ConfigureStore";
import { depthData } from "../redux/Feature/Depth/DepthSlice";
import { klinePush } from "../redux/Feature/Kline/KlineSlice";

function Ranger() {
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));

  const rangerBaseUrl = isAuthenticate
    ? `${rangerUrl()}/private`
    : `${rangerUrl()}/public`;

  const { currentMarketId } = useSelector(
    (state) => state?.exchange
  );
  const currentPeriod = useSelector((state) => state?.exchange?.klinePeriod);
  const streams = streamsBuilder(currentMarketId, currentPeriod);

  const dispatchRecentTrades = useCallback((event) => {
    dispatch(recentTradesPush(event));
  }, [dispatch]);

  useEffect(() => {
    if (currentMarketId !== undefined) {
      const ws = new WebSocket(generateSocketURI(rangerBaseUrl, streams));

      ws.onopen = function () {
        dispatch(rangerConnectData(true));
      };

      ws.onmessage = function ({ data }) {
        let payload = {};
        try {
          payload = JSON.parse(data);
        } catch (e) {
          window.console.error(`Error parsing : ${e.data}`);
        }

        for (const routingKey in payload) {
          if (payload.hasOwnProperty(routingKey)) {
            const event = payload[routingKey];
            var state = store.getState();
            const currentMarket = selectCurrentMarket(state);
            const orderBookMatch = routingKey.match(/([^.]*)\.update/);

            // public
            if (orderBookMatch) {
              if (currentMarket && orderBookMatch[1] === currentMarket.id) {
                dispatch(depthData(event));
              }
              return;
            }

            const klineMatch = String(routingKey).match(/([^.]*)\.kline-(.+)/);
            if (klineMatch) {
              dispatch(
                klinePush({
                  marketId: klineMatch[1],
                  kline: event,
                  period: klineMatch[2],
                })
              );
              return;
            }
            const tradesMatch = String(routingKey).match(/([^.]*)\.trades/);
            if (tradesMatch) {
              let events = {
                trades: event.trades,
                market: tradesMatch[1],
              }
              dispatchRecentTrades(events);
              return;
            }

            switch (routingKey) {
              case "global.tickers":
                dispatch(setMarketTickers(formatTicker(event)));
                break;
              case `${currentMarketId}.update`:
                dispatch(setOrderBook({ orderBook: event }));
                break;
              case `${currentMarketId}.trades`:
                dispatch(setTrades({ trade: event?.trades[0] }));
                break;
              case "favmarket":
                dispatch(getRangerFavMarketUpdate({ favmarket: event }));
                break;

              case `${currentMarketId}.kline-${currentPeriod}`:
                dispatch(
                  setKlineData({
                    data: [],
                    last: event,
                    loading: false,
                    marketId: currentMarketId,
                    period: currentPeriod,
                  })
                );
                break;

              default:
                break;
            }
          }
        }
      };

      ws.onclose = function (event) {
        dispatch(rangerConnectData(false));
      };
      return () => ws.close();
    }
  }, [currentPeriod, currentMarketId, isAuthenticate, dispatch]);
}
export default withTranslation()(Ranger);
