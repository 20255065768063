export const peatio = {
    apiVersion: "peatio",
};

export const peatioWithHeader = {
    apiVersion: "peatio",
    withHeaders: true
};

export const barong = {
    apiVersion: "barong",
};
