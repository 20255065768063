import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assetsLink, cryptoIconUrl, uppercase } from "../../helpers";
import "./Landing.scss";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "../../redux/Selector/User";
import { Button, Col, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import { AppleFilled } from "@ant-design/icons";
import { PlayStore } from "../../assets/icons/Svgicon";
import { selectMarketTickers } from "../../redux/Selector/markets";
import { getIconUrl, selectCurrencies } from "../../redux/Selector/currencies";
import {
  getMarkets,
  marketGetTickers,
} from "../../redux/Services/ExchangeService";
import { getCurrencies } from "../../redux/Services/Common";
import { useWindowWidthAndHeight } from "../CustomHooks/CustomHooks";
import { customLocale } from "../../helpers/customLocale";

function Landing(props) {
  const [width, height] = useWindowWidthAndHeight();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => isAuthenticated(state));
  const tickers = useSelector((state) => selectMarketTickers(state));
  const currencies = useSelector((state) => selectCurrencies(state));
  const markets = useSelector((state) => state.exchange.marketList);

  const [marketData, setMarketData] = useState([]);

  const { level } = useSelector((state) => state.user.data);

  const { t } = useTranslation();

  const {
    addFund,
    tradecrypto,
    buildcrypto,
    transactions,
    easysignup,
    Engine,
    Secure,
    Highliquidity,
    Quickpayments,
    Multiasset,
    Multichain,
    FuzzyBeginnings,
    RocketImg,
  } = assetsLink;

  const tradecryptoOptios = [
    {
      id: 1,
      icon: tradecrypto,
      heading: <> {t("landing.info.heading1")}</>,
      text: <> {t("landing.info.desc1")}</>,
    },
    {
      id: 2,
      icon: buildcrypto,
      heading: <>{t("landing.info.heading2")}</>,
      text: <>{t("landing.info.desc2")}</>,
    },
    {
      id: 3,
      icon: transactions,
      heading: <>{t("landing.info.heading3")}</>,
      text: <>{t("landing.info.desc3")}</>,
    },
    {
      id: 4,
      icon: easysignup,
      heading: <>{t("landing.info.heading4")}</>,
      text: <>{t("landing.info.desc4")}</>,
    },
  ];

  const blockCards = [
    {
      id: 1,
      icon: Multiasset,
      heading: <>{t("landing.services.info.heading1")}</>,
      content: <> {t("landing.services.info.desc1")}</>,
    },
    {
      id: 2,
      icon: Multichain,
      heading: <>{t("landing.services.info.heading2")}</>,
      content: <>{t("landing.services.info.desc2")}</>,
    },
    {
      id: 3,
      icon: Quickpayments,
      heading: <>{t("landing.services.info.heading3")}</>,
      content: <>{t("landing.services.info.desc3")}</>,
    },
    {
      id: 4,
      icon: Highliquidity,
      heading: <>{t("landing.services.info.heading4")}</>,
      content: <>{t("landing.services.info.desc4")}</>,
    },
    {
      id: 5,
      icon: Secure,
      heading: <>{t("landing.services.info.heading5")}</>,
      content: <>{t("landing.services.info.desc5")}</>,
    },
    {
      id: 6,
      icon: Engine,
      heading: <>{t("landing.services.info.heading6")}</>,
      content: <>{t("landing.services.info.desc6")}</>,
    },
  ];

  useEffect(() => {
    dispatch(getMarkets());
    dispatch(marketGetTickers());
    dispatch(getCurrencies());
    if (window.location.pathname === "/") {
      onScrollClass();
    }
    return () => {
      // document.body.classList.remove("landing");
    };
  }, []);

  const onScrollClass = () => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        document.body.classList.add("scrollHeader");
      } else {
        document.body.classList.remove("scrollHeader");
      }
    });
  };

  useEffect(() => {
    let data = [];

    if (markets.length > 0 && Object.keys(tickers).length > 0) {
      let coins = Object.keys(tickers);
      let index = 0;
      for (let i = 0; i < coins.length; i++) {
        let uniqMarkets = markets.filter((m) => m.id === coins[i]);

        if (uniqMarkets[0]?.quote_unit === "usdt") {
          index = index + 1;
          data.push({
            last: tickers[uniqMarkets[0]?.id]?.last,
            price_change_percent:
              tickers[uniqMarkets && uniqMarkets[0]?.id].price_change_percent,

            total_volume:
              tickers[uniqMarkets[0]?.id].total_volume_base_currency,
            currecny: tickers[uniqMarkets[0]?.id].base_unit,
            image: (
              <img
                alt="cryptoIcon"
                src={cryptoIconUrl(
                  coins[i],
                  getIconUrl(currencies, uniqMarkets[0]?.base_unit)
                )}
              />
            ),
            market: uniqMarkets[0]?.id,
            name: currencies?.filter(
              (m) => m.id === tickers[uniqMarkets[0]?.id].base_unit
            )[0],
          });
        }
      }
      setMarketData(data);
    }
  }, [markets, tickers]);

  const redirectBtn = () => {
    !isAuth
      ? history(`/register`)
      : level === 3
        ? history("/trading")
        : history("/settings");
  };

  const sortedMarketData = marketData.slice().sort((a, b) => b.total_volume - a.total_volume);

  const dataSource =
    sortedMarketData?.length > 0 &&
    sortedMarketData?.map((item, idx) => {
      const {
        image,
        name,
        currecny,
        last,
        price_change_percent,
        total_volume,
        market,
      } = item;
      let currName = name?.name.split(" ")[0];
      return {
        key: idx,
        name: (
          <div
            style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}
          >
            <img src={image.props.src} alt="Coin" width={44} height={44} />
            <p>
              <span>{currName}</span>
              <br />
              <span>{uppercase(currecny)}</span>
            </p>
          </div>
        ),
        lastPrice: last || 0,
        change: (
          <span
            className={
              price_change_percent?.charAt(0) === "+" ? "positive" : "negative"
            }
          >
            {price_change_percent || 0}
          </span>
        ),
        volume: total_volume || 0,
        marketCap: `$ ${name?.price || 0}`,
        trade: (
          <Button onClick={() => history(`/trading/${market}`)}>
            {t("landing.ticker.trade")}
          </Button>
        ),
      };
    });

  const columns = [
    {
      title: t("landing.ticker.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("landing.ticker.last_price"),
      dataIndex: "lastPrice",
      key: "lastPrice",
    },
    {
      title: t("landing.ticker.24h_change"),
      dataIndex: "change",
      key: "change",
    },
    {
      title: t("landing.ticker.24h_volume"),
      dataIndex: "volume",
      key: "volume",
      // sorter: (a, b) => b.volume - a.volume,
    },
    {
      title: t("landing.ticker.market_cap"),
      dataIndex: "marketCap",
      key: "marketCap",
    },
    {
      title: t("landing.ticker.trade"),
      dataIndex: "trade",
      key: "trade",
    },
  ];

  return (
    <>
      <div className="EmperiorCoin-landing">
        <section className="euroMultiCurrency  p-60">
          <div style={{ paddingBottom: "80px" }} className="container-custom">
            <Row
              gutter={[30]}
              align="middle"
              justify={width >= 991 ? "space-between" : "center"}
            >
              <Col lg={12}>
                <div className="euroMultiCurrency__Everything__left">
                  <p style={{ marginBottom: "20px" }}>{t("landing.title")}</p>
                  <h2>{t("landing.secure_trusted")}</h2>
                  <p className="buysellContent">
                    {t("landing.ease_confidence")}
                  </p>
                  <div className="get"></div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="euroMultiCurrency__Everything__right">
                  <img src={addFund} className="fundADDImg" alt="img" />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="yellow-block">
          <div className="cryptoChain container-custom">
            <Row
              justify={width >= 991 ? "space-between" : "center"}
              gutter={[30, 30]}
            >
              {tradecryptoOptios.map((blocks, index) => (
                <Col md={12} lg={6} key={index}>
                  <div key={index} className="cryptoChain__innerCryptoChain">
                    <img src={blocks.icon} alt="icon" />
                    <h3 style={{ margin: "10px 0" }}>{blocks.heading}</h3>
                    <p>{blocks.text}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
        <section className="about p-60">
          <div className="container-custom">
            <Row
              justify={width >= 991 ? "space-between" : "center"}
              gutter={[30, 30]}
            >
              <Col lg={12}>
                <img className="responsive" src={RocketImg} alt="img" />
              </Col>
              <Col lg={12}>
                <div className="commmonHeading ">
                  <h2>{t("landing.about.title")}</h2>
                  <h3>{t("landing.about.header")}</h3>
                  <h4>{t("landing.about.heading")}</h4>
                  <p>{t("landing.about.desc")}</p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section style={{ marginBottom: "60px" }}>
          <div className="container-custom">
            <div
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="commmonHeading "
            >
              <h2>{t("landing.popularCryptos.title")}</h2>
              <h3>{t("landing.popularCryptos.heading")}</h3>
            </div>
            <div
              style={{ overflowX: " auto" }}
              className="custom-table card-outer-basic "
            >
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={customLocale}
              />
            </div>
          </div>
        </section>
        <section className="servicesProvide p-60">
          <div className="container-custom">
            <div
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="commmonHeading "
            >
              <h2>{t("landing.services.title")}</h2>
              <h3>{t("landing.services.heading")}</h3>
              <p style={{ textAlign: "center" }}>
                {t("landing.services.desc")}
              </p>
            </div>
            <Row gutter={[30, 30]}>
              {blockCards.map((blocks, index) => (
                <>
                  <Col md={12} lg={8} key={index}>
                    <div
                      key={blocks.id}
                      className="servicesProvide__globalSecInner"
                    >
                      <div className="imgDiv">
                        <img src={blocks.icon} alt="icon" />
                      </div>
                      <h4>{blocks.heading}</h4>
                      <p>{blocks.content}</p>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </div>
        </section>
        <section className="updated p-60">
          <div className="container-custom">
            <Row
              gutter={[30, 30]}
              align="middle"
              justify={width >= 991 ? "space-between" : "center"}
            >
              <Col lg={12}>
                <div className="commmonHeading">
                  <h2>{t("landing.mobile_app.title")}</h2>
                  <h3>{t("landing.mobile_app.heading")}</h3>
                  <p>{t("landing.mobile_app.desc")}</p>

                  <div className="buttons">
                    <Button
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "100%",
                        padding: "10px 20px",
                        minWidth: 184,
                      }}
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=com.emperorcoin', '_blank')}
                    >
                      <PlayStore />
                      <span style={{ textAlign: "left" }}>
                        {t("landing.app_on")}
                        <br />
                        <strong>Google Play</strong>
                      </span>
                    </Button>

                    <Button
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        height: "100%",
                        padding: "10px 20px",
                        minWidth: 184,
                      }}
                      onClick={() => window.open('https://apps.apple.com/us/app/emperor-coin/id6473788588', '_blank')}
                    >
                      <AppleFilled style={{ fontSize: "24px" }} />
                      <span style={{ textAlign: "left" }}>
                        {t("landing.download")}
                        <br />
                        <strong>App Store</strong>
                      </span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <img className="responsive" src={FuzzyBeginnings} alt="img" />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </>
  );
}

export default Landing;
