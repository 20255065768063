import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { errorToastSelector } from "../Selector/ToastSelector";
import { startLoading, stopLoading } from "../Feature/Index";

export const fetchAddress = createAsyncThunk(
    "address/fetchAddress",
    async (payload, { rejectWithValue, dispatch }) => {
        const { currency, level, blockchain_key } = payload
        try {
            if (currency !== "" && level > 2) {
                dispatch(startLoading());
                let url = `/account/deposit_address/${currency === "usd" ? "usdt" : currency === "eur" ? "usdt" : currency}?blockchain_key=${blockchain_key}`;
                let res = await API.get(config.peatio)(url);
                dispatch(stopLoading());
                return { list: res, status: true };
            }

        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);