import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { errorToastSelector } from "../Selector/ToastSelector";
import { startLoading, stopLoading } from "../Feature/Index";

export const getCurrencies = createAsyncThunk(
    "common/getCurrencies",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = ``;
            if (payload?.id) {
                url = `public/currencies/${payload.id}`;
            } else if (payload?.type) {
                url = `public/currencies?type=${payload?.type}`;
            } else {
                url = `public/currencies`;
            }
            let res = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return { list: res, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const fetchBalance = createAsyncThunk(
    "common/fetchBalance",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `account/balances`;
            if (typeof payload != 'undefined') {
                url = `/account/balances/${payload}`;
            }
            let res = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return { list: res, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const updatePopUpStatus = createAsyncThunk(
    "common/updatePopUpStatus",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/resource/users/pop_status`;
            let res = await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);