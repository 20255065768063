import { createSlice } from "@reduxjs/toolkit";
import { getUser, updateUser } from "../../Services/UserServices";


export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {
    },
    error: false,
    userIsLoading: null,
    // loading: false,
  },
  reducers: {
    verifyUser: (state, action) => {
      state.data.otp = action.payload.otp;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state, { meta }) => {
        state.userIsLoading = meta?.arg?.isNull ? null : false;
        state.error = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.data = { ...action.payload };
        state.userIsLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.data = {};
        state.error = true;
        state.userIsLoading = false;
      })

      .addCase(updateUser.pending, (state, { meta }) => {
        state.loading = true
        state.error = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default userSlice.reducer;
export const { verifyUser } = userSlice.actions
