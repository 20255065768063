export const statusFormat = str => {
  switch (str) {
    case 'pendingPayment':
      return 'Pending';
    case 'waitingPayment':
      return 'Waiting';
    case 'paymentReceived':
      return 'Received';
    case 'inProgress':
      return 'In progress';
    case 'coinTransferred':
      return 'Coin-Transferred';
    case 'cancelled':
      return 'Cancelled';
    case 'declined':
      return 'Declined';
    case 'expired':
      return 'Expired';
    case 'complete':
      return 'Complete';
    case 'refunded':
      return 'Refunded';
    case 'CRYPTO-BUY':
      return 'Buy';
    case 'CRYPTO-SELL':
      return 'Sell';
    default:
      return str;
  }
};

export const commonStaus = str => {
  switch (str) {
    case 'complete':
      return 'completed';
    case 'completed':
      return 'completed';
    case 'pending':
      return 'pending';
    case 'processing':
      return 'processing';
    case 'prepared':
      return 'prepared';
    case 'confirming':
      return 'confirming';
    case 'succeed':
      return 'succeed';
    case 'collecting':
      return 'collecting';
    case 'collected':
      return 'collected';
    case 'rejected':
      return 'rejected';
    case 'skipped':
      return 'processing';
    case 'failed':
      return 'failed';
    case 'canceled':
      return 'canceled';
    case 'errored':
      return 'processing';
    default:
      return str;
  }
};
