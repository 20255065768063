import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { checkEmailStatus, checkPhoneStatus, setPhoneStatusWhenChange, setRegisterData, startLoading, stopLoading } from "../Feature/Index";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";
import { buildQueryString } from "../../helpers";

export const verifyAccount = createAsyncThunk(
    "verify/verifyAccount",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity/users/verify_account`;
            await API.post(config.barong)(url, payload);
            dispatch(successToastSelector({ message: ['resource.profile.verified'] }));
            dispatch(stopLoading());
            localStorage.clear();
            sessionStorage.clear();
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const resendOtpPhone = createAsyncThunk(
    "verify/resendOtpPhone",
    async (payload, { dispatch }) => {
        try {
            const { otpClicked, t } = payload;
            delete payload.otpClicked;
            dispatch(startLoading());
            let url = `identity/users/send_otp/phone`;
            await API.post(config.barong)(url, payload);
            dispatch(successToastSelector({
                message: [
                    otpClicked ?
                        t("register.verify.phone_resent_otp") :
                        t("register.verify.phone_sent_otp")]
            }));
            dispatch(stopLoading());
            dispatch(checkPhoneStatus({
                status: true
            }));
            dispatch(setPhoneStatusWhenChange({
                status: true
            }));
            localStorage.clear();
            sessionStorage.clear();
            return { status: true };
        } catch (e) {
            dispatch(checkPhoneStatus({
                status: false
            }))
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const resendOtpEmail = createAsyncThunk(
    "verify/resendOtpEmail",
    async (payload, { dispatch }) => {
        try {
            const { otpClicked, t } = payload;
            delete payload.otpClicked;
            dispatch(startLoading());
            let url = `identity/users/send_otp/email`;
            await API.post(config.barong)(url, payload);
            dispatch(successToastSelector({
                message: [
                    otpClicked ?
                        t("register.verify.email_resent_otp") :
                        t("register.verify.email_sent_otp")]
            }));
            dispatch(stopLoading());
            dispatch(checkEmailStatus({
                status: true
            }));
            localStorage.clear();
            sessionStorage.clear();
            return { status: true };
        } catch (e) {
            dispatch(checkEmailStatus({
                status: false
            }))
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const changeNumber = createAsyncThunk(
    "verify/changeNumber",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const { t } = payload;
            delete payload.t;
            dispatch(startLoading());
            let url = `/identity/users/edit/phone_number?${buildQueryString(
                payload
            )}`;
            await API.post(config.barong)(url, payload);
            dispatch(successToastSelector({
                message: t("change_phone.otp")
            }));
            dispatch(checkPhoneStatus({
                status: true
            }));
            dispatch(setPhoneStatusWhenChange({
                status: true
            }));
            dispatch(setRegisterData({
                phone_number: payload.new_phone_number,
                email: payload.email
            }))
            dispatch(stopLoading());
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return rejectWithValue(e);
        }
    }
);
