import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { errorToastSelector } from "../Selector/ToastSelector";
import { startLoading, stopLoading } from "../Feature/Index";

export const saveSumsubApplicant = createAsyncThunk(
    "sumsub/saveSumsubApplicant",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `/resource/sumsub/applicant_id`;
            const data = await API.post(config.barong)(url, payload);
            dispatch(stopLoading());
            return data.token;
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const fetchSumsubToken = createAsyncThunk(
    "sumsub/fetchSumsubToken",
    async (payload, { dispatch }) => {
        try {
            // dispatch(startLoading());
            let url = `/resource/sumsub/token`;
            const data = await API.get(config.barong)(url, payload);
            // dispatch(stopLoading());
            return data;
        } catch (e) {
            // dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);


