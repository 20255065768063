import { createSlice } from "@reduxjs/toolkit";
import { depthFetch } from "../../Services/DepthService";

export const depthSlice = createSlice({
    name: "depth",
    initialState: {
        asks: [],
        bids: [],
        loading: false
    },
    reducers: {
        depthData: (state, action) => {
            let { asks, bids } = action.payload;
            asks = Object.assign([], asks.reverse());
            state.asks = asks;
            state.bids = bids;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(depthFetch.pending, (state) => {
                state.error = false;
                state.depthLoading = true
            })
            .addCase(depthFetch.fulfilled, (state, action) => {
                state.depthLoading = false;
                let { asks, bids } = action.payload.list;
                asks = Object.assign([], asks.reverse());
                state.asks = asks;
                state.bids = bids


            })
            .addCase(depthFetch.rejected, (state, action) => {
                state.error = true;
                state.depthLoading = false

            })
    },
});

export const {
    depthData
} = depthSlice.actions;
export default depthSlice.reducer;
