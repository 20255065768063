import { createSlice } from "@reduxjs/toolkit";
import { userCreate } from "../../Services/Signup";

export const regiterSlice = createSlice({
    name: "register",
    initialState: {
        error: false,
        email: "",
        phone_number: "",
        loading: false
    },
    reducers: {
        setRegisterData: (state, action) => {
            state.phone_number = action.payload.phone_number
            state.email = action.payload.email
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userCreate.pending, (state) => {
                state.error = false;
                state.data = {}
            })
            .addCase(userCreate.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.phone_number = action.payload.phone;
                state.email = action.payload.email
            })
            .addCase(userCreate.rejected, (state) => {
                state.error = true;
                state.data = {}
            });
    },
});
export default regiterSlice.reducer;
export const { setRegisterData } = regiterSlice.actions;
