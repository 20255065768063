// const cryptoIcons = require.context(
//   '../../node_modules/cryptocurrency-icons/svg/color',
//   true
// );

export const cryptoIconUrl = (str, url) => {
  if (url) {
    return url;
  }

  try {
    // return cryptoIcons(`./${str}.svg`);
  } catch (e) {
    // return cryptoIcons('./generic.svg');
  }
};
