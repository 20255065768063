import { createSlice } from "@reduxjs/toolkit";
import { fetchBuySellData, fetchBuySellHistory } from "../../Services/BuySellServices.js";

const buySellSlice = createSlice({
    name: "buySell",
    initialState: {
        list: [],
        total: 0,
        currency: "",
        loading: false,
        data: [],
        error: false,
        curret_Tab: "Buy",
        transStatus: false
    },
    reducers: {
        setBuySellTab: (state, action) => {
            state.curret_Tab = action.payload
        },
        setTransStatus: (state, action) => {
            state.transStatus = action.payload
        },
        selectedCurrency: (state, action) => {
            state.currency = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBuySellHistory.pending, (state) => {
                state.error = false;
                // state.loading = true;
                state.list = []
            })
            .addCase(fetchBuySellHistory.fulfilled, (state, action) => {
                // state.loading = false;
                state.list = action.payload.list;
                state.total = action.payload.total
            })
            .addCase(fetchBuySellHistory.rejected, (state, action) => {
                state.error = true;
                // state.loading = false;
            })

            .addCase(fetchBuySellData.pending, (state) => {
                state.error = false;
                state.loading = true;
                state.data = []
            })
            .addCase(fetchBuySellData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchBuySellData.rejected, (state, action) => {
                state.error = true;
                state.loading = false;
            })
    },
});

export const { setBuySellTab, setTransStatus, selectedCurrency } = buySellSlice.actions;

export default buySellSlice.reducer;
