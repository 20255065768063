// tslint:disable:no-any
export const buildQueryString = action =>
  Object.entries(action)
    .filter(w => w[1] !== '')
    .map(k => `${k[0]}=${encodeURIComponent(k[1])}`)
    .join('&');


export const buildQueryString2 = (params) =>
  Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${v}`).join('&');
      }
      return `${key}=${value}`;
    })
    .join('&');