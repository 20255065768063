export const orderStateMap = state => {
  switch (state) {
    case 'wait': {
      return 'new';
    }
    case 'pending': {
      return 'partial';
    }
    case 'done': {
      return 'done';
    }
    case 'cancel': {
      return 'cancelled';
    }
    case 'reject': {
      return 'rejected';
    }
    default: {
      return 'new';
    }
  }
};
