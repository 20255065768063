import { createSlice } from "@reduxjs/toolkit";
import { getIdentity } from "../../Services/Login";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    error: false,
    data: {},
    otpEnabled: false,
    loading: false,
    loginLoading: false,
    theme: localStorage.getItem('ThemeMode') || 'dark',
    left: '',
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.pending, (state) => {
        state.error = false;
        state.data = {}
        state.loading = true;
        state.loginLoading = true
      })
      .addCase(getIdentity.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.otpEnabled = action.payload.otp
        state.error = false;
        state.left = "";
        state.loading = false;
        state.loginLoading = false
      })
      .addCase(getIdentity.rejected, (state, action) => {
        state.error = true;
        state.data = {}
        state.left = action?.payload?.left ? action?.payload?.left : ''
        state.otpEnabled = false
        state.loading = false
        state.loginLoading = false

      });
  },
});
export default loginSlice.reducer;
export const { setTheme } = loginSlice.actions;
