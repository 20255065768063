import { createAsyncThunk } from "@reduxjs/toolkit";
import * as config from "../../utils/apiVersion";
import { API } from "../../api";
import { setRegisterData, startLoading, stopLoading } from "../Feature/Index";
import { getUser } from "./UserServices";
import { errorToastSelector, successToastSelector } from "../Selector/ToastSelector";

export const getIdentity = createAsyncThunk(
    "login/getIdentity",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity`;
            const res = await API.post(config.barong)(url, payload);
            if (res?.level === 0 && res?.state !== 'active') {
                dispatch(createIdentitySession({
                    email: payload.email,
                    code: '',
                    captcha_response: payload.captcha_response,
                    phone_number: 'false',
                    session_type: 'email',
                    password: payload.password,
                    otp_code: '',
                    authentication_state: payload.authentication_state,
                    nav: payload.nav
                }))
            }
            dispatch(stopLoading());
            return { data: res, status: true, otp: res?.otp };
        } catch (e) {
            const invalidErrorTypes = [
                "identity.session.invalid_params",
                "identity.session.invalid_phone_number"];
            dispatch(stopLoading());

            if (e.message[0] === 'invalid_email_phone_or_password_attempts_left') {
                return e.message[1].attempts;
            }

            if (e.message[0] === "identity.session.user_blocked") {
                const { min, sec } = e.message[1];
                dispatch(errorToastSelector({
                    message: [`${payload.t("login.blocked.error")} ${min} ${payload.t("login.blocked.min")} ${sec} ${payload.t("login.blocked.sec")}`],
                    code: e.code,
                    type: 'error',
                }));
                return false;
            }

            if (invalidErrorTypes.includes(e.message[0])) {
                dispatch(errorToastSelector(e));
                return rejectWithValue(e);
            }

            const checkLeft = e.message[0].replace(/^\D+/g, '');
            const leftNum = checkLeft.match(/\d+/)[0];
            const leftText = e.message[0].replace(/[0-9]/g, '');

            if (leftText === "Invalid Email/Phone or Password Attempts Left ") {
                dispatch(errorToastSelector({
                    message: [`${payload.t("left_num")} ${leftNum}`]
                }));
                return rejectWithValue(e);
            }
        }
    }
);

export const getOtpService = createAsyncThunk(
    "login/getOtpService",
    async (payload, { dispatch }) => {
        try {
            const { otpClicked, t } = payload;
            delete payload.otpClicked;
            delete payload.t;
            let url = `identity/sessions/resent_otp`;
            await API.post(config.barong)(url, payload);
            dispatch(
                successToastSelector({
                    message: [
                        otpClicked && payload.session_type === "email" ? t("login.email_resent_otp") :
                            !otpClicked && payload.session_type === "email" ? t("login.email_sent_otp") :
                                otpClicked && payload.session_type === "phone" ? t("login.phone_resent_otp")
                                    : t("login.phone_sent_otp")
                    ]
                })
            );
            return { status: true };
        } catch (e) {
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const createIdentitySession = createAsyncThunk(
    "login/createIdentitySession",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity/sessions`;
            let res = await API.post(config.barong)(url, payload);
            localStorage.setItem("csrfToken", res.csrf_token);
            if (res.level === 0) {
                dispatch(setRegisterData({
                    phone_number: res?.phones[0]?.number,
                    email: res?.email,
                }))

                payload?.nav('/verify-account');
                return;
            }
            payload?.nav('/settings');
            dispatch(successToastSelector({ message: ['identity.session.login'] }));
            dispatch(stopLoading());
            dispatch(getUser());
            return { status: true, data: res };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);
