import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
    name: "alert",
    initialState: {
      message:'',
      type:"",
      code:""
    },
    reducers: {
      showAlert: (state,action) => {
        state.message = action.payload.message;
        state.type = action.payload.type;
        state.code = action.payload.code;
      },
    }
  });
  export default alertSlice.reducer
  export const { showAlert } = alertSlice.actions;